export const erc20PaidFactoryABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_staffMemberAddress",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "_name",
                "type": "string"
            },
            {
                "internalType": "enum ERC20Factory.Roles",
                "name": "_role",
                "type": "uint8"
            }
        ],
        "name": "addStaff",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newFee",
                "type": "uint256"
            }
        ],
        "name": "changeFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address payable",
                "name": "_newCollector",
                "type": "address"
            }
        ],
        "name": "changeFeeCollector",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "name_",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "symbol_",
                "type": "string"
            },
            {
                "internalType": "bool",
                "name": "isBurnable_",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "isMintable_",
                "type": "bool"
            },
            {
                "internalType": "uint256",
                "name": "initialSupply_",
                "type": "uint256"
            }
        ],
        "name": "create",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "symbol",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "initialSupply",
                        "type": "uint256"
                    },
                    {
                        "internalType": "string",
                        "name": "tokenType",
                        "type": "string"
                    },
                    {
                        "internalType": "bool",
                        "name": "isBurnable",
                        "type": "bool"
                    },
                    {
                        "internalType": "bool",
                        "name": "isMintable",
                        "type": "bool"
                    },
                    {
                        "internalType": "address",
                        "name": "tokenAddress",
                        "type": "address"
                    }
                ],
                "indexed": false,
                "internalType": "struct Token",
                "name": "_token",
                "type": "tuple"
            },
            {
                "indexed": false,
                "internalType": "enum ERC20Factory.Roles",
                "name": "_role",
                "type": "uint8"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "_feePaid",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "bytes",
                "name": "_data",
                "type": "bytes"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "_fromAddress",
                "type": "address"
            }
        ],
        "name": "Created",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "feeCollector",
        "outputs": [
            {
                "internalType": "address payable",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "LISTING_FEE",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address payable",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "staff",
        "outputs": [
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            },
            {
                "internalType": "enum ERC20Factory.Roles",
                "name": "role",
                "type": "uint8"
            },
            {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]