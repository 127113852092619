<template>

      <h1 class="text-color text-3xl font-bold px-6">
        {{!isConnected ? "Please connect your crypto wallet to continue." : "Wallet is connected"}}
      </h1>
      <div class="flex w-full h-1/5 justify-evenly max-w-3xl mx-auto px-8">
        <lottie v-if="!isConnected"  :options="walletAnimation" :height="150" :width="150" class="" />
        <lottie v-if="!isConnected"
            :options="bridgeAnimation" :height="150" :width="150" />
        <lottie v-else
             :options="chekmarkAnimation" :height="180" :width="180" />


        <lottie v-if="!isConnected"  :options="logoAnimation" :height="150" :width="150" />
      </div>


</template>

<script>
import Lottie from "@/lottie";
import * as LogoAnimation from "@/assets/animations_json/osis-logo-lottie.json";
import * as WalletAnimation from "@/assets/animations_json/wallet-lottie.json";
import * as BridgeAnimation from "@/assets/animations_json/connect.json";
import * as CheckMarkAnimation from "@/assets/animations_json/checkmark.json";
export default {
  name: "ConnectWallet",

  components: {
    'lottie': Lottie
  },
  emits: ['on-connect-button', 'on-skip-wizard' ],
  props: [
    'buttonText', 'isConnected'
  ],
  data () {
    return {
      connectButtonText: this.buttonText,
      logoAnimation: {animationData: LogoAnimation,
      loop: true
      },
      walletAnimation: {animationData: WalletAnimation,
      loop: true},
      bridgeAnimation: {
        animationData: BridgeAnimation,

      },
      chekmarkAnimation : {
        animationData: CheckMarkAnimation,
        loop: false
      },

    }
  },
  methods:{

  }
}
</script>

<style scoped>
.text-color{
  color: #212144;
}
</style>