<template>
  <button
      class="px-8 w-40 py-4 rounded-full  border-2 mt-12 border-[#4FA9DB] hover:bg-blue-100"
  >
    <h2 class=" text-[#4FA9DB] font-medium whitespace-nowrap">{{buttonText}}</h2>
  </button>
</template>

<script>
export default {
  props: [
    'buttonText',
  ],
  name: "OutlineButton"
}
</script>

<style scoped>

</style>