<template>

    <div ref="message" class="h-10 fade-in-out-text text-xl px-10 my-6 text-center font-medium text-gray-800" >{{currentMessage}} {{textIndex===0 ? "&#8987; \n":""}}</div>

</template>

<script>
export default {
  name: "FadeInOutText",
  props:['messagesList'],
  data() {
    return {

      currentMessage:"",
      textIndex:0,
      animationDuration: 3000,
      count:0,

    }
  },
  methods: {


  },
  mounted() {

    this.$refs.message.style.setProperty("--animation-time",(this.animationDuration/1000).toString()+'s')
    this.currentMessage = this.messagesList[this.textIndex];
    this.textIndex ++;
    setInterval( ()=> {
      this.currentMessage = this.messagesList[this.textIndex];
      this.textIndex ++;
      if(this.textIndex === this.messagesList.length){this.textIndex = 0}
    }, this.animationDuration);
  }

}
</script>


<style scoped>
:root{
  --animation-time : 3s;
}
.fade-in-out-text{
  animation: animation-key-frames var(--animation-time, 3s) infinite;
}
@keyframes animation-key-frames {
  0% ,5%, 95%,100%{ opacity: 0; }
  20% , 80% { opacity: 1; }
}
</style>