<template>
<h1>Number of pages in your doc is : {{pageCount}} value loging = {{progress}}</h1>
  <input type="file" ref="file"  @change="readFile" accept="application/pdf">>
  <pdf class="w-44 h-44" :src="previewImage"
       @num-pages="pageCount = $event"
       @click="openOsis"
       @progress="progress = $event"
       :page="pageid"
  ></pdf>
</template>

<script>
import pdf from '@jbtje/vue3pdf'
export default {
  name: "TestingPdf",
  components: {
    pdf,

  },
  data() {
    return {
      previewImage : '',
      pageCount: null,
      progress :'',
      pageid : 1
    }
  },
  methods: {
    readFile() {
      this.inputRef = this.$refs.file.files[0]
      this.previewImage = URL.createObjectURL(this.inputRef);

    },
    openOsis() {
      // window.open(URL.createObjectURL(this.inputRef), "_blank");
      this.pageid ++
    }
  }

}
</script>

<style scoped>

</style>