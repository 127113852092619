import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import Home from './Home.vue'
import TokenList from './TokenList.vue'
import CreateToken from './CreateToken.vue'
import NotFound from './NotFound.vue'
import './assets/tailwind.css'

import TesterPage from "@/TestingPage";
import TestingPdf from "./TestingPdf";

const routes = [
    { path: '/', component: Home ,name:'home'},
    { path: '/tokens', component: TokenList },
    { path: '/testing', component: TesterPage, name:'TestingPage' },
    { path: '/pdf', component: TestingPdf, name:'TestingPdf' },
    { path: '/token/create', component: CreateToken, props: true, name:'token_create' },
    { path: '/:pathMatch(.*)*', component: NotFound }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')