<template>
  <div :class="{'hidden': isHidden}" class = " h-[8%] max-h-20  w-full relative pl-4 flex items-center">
    <img  @click="$emit('nav-back-button')" class="h-3/5 left-4" src="../assets/nav-back-ic.svg" alt="">
  </div>
<!--  <div class="lg:hidden flex p-4 justify-center items-center relative">-->
<!--    <img  @click="$emit('nav-back-button')" class="h-8 absolute left-4 " src="../assets/nav-back-ic.svg" alt="">-->
<!--    <div   class="lg:hidden flex justify-center items-center h-10 w-full ">-->
<!--      <div class="flex pt-2 px-4 w-2/3 justify-center items-center relative gap-2 max-w-sm">-->
<!--        <div class="grow h-1 rounded-lg bg-[#212144]"></div>-->
<!--        <div class="grow h-1 rounded-lg bg-[#212144]"></div>-->
<!--        <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--        <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--        <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--        <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--        <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--      </div>-->
<!--    <div class="flex flex-col items-center space-y-1">-->
<!--      <img class="h-8" src="../assets/logo_footer.svg" alt="">-->
<!--      <h1 class="text-md font-bold text-[#4FA9DB]">Launchpad</h1>-->
<!--    </div>-->
<!--    <h-3  class="absolute right-4 text-[#4FA9DB] text-xl font-bold" src="../assets/nav-back-ic.svg" alt="">1/8</h-3>-->
<!--    <img class="h-8" src="../assets/logo_footer.svg" alt="">-->
<!--    <h1 class="text-2xl py-2 font-bold text-[#4FA9DB]">Launchpad</h1>-->

<!--    <div  v-if="!isConnected" @click="connectTesting" class="rounded-full h-14 w-14 flex  justify-center items-center bg-gray-300 ">-->
<!--      <img class="h-8" src="../assets/osis-logo-ic.svg" alt="">-->
<!--    </div>-->

<!--    <h1   v-if="!isConnected" class="text-2xl py-2 font-bold text-color">Launchpad</h1>-->


<!--    <div v-else class="rounded-full h-14 w-44 flex justify-end items-center bg-gray-300 relative  ">-->
<!--      <h1 class="text-xl px-2 font-medium text-gray-700">3ad...2971</h1>-->
<!--      <img class="h-12 w-12 p-2 mr-1 rounded-full bg-[#212144]" src="../assets/osis-logo-ic-white.svg" alt="">-->
<!--    </div>-->
<!--    <div/>-->
<!--  </div>-->
<!--  <div  v-if="isConnected" class="lg:hidden flex justify-center items-center ">-->
<!--    <div class="flex pt-2 px-4 w-2/3 justify-center items-center relative gap-2 max-w-sm ">-->
<!--      <div class="grow h-1 rounded-lg bg-[#212144]"></div>-->
<!--      <div class="grow h-1 rounded-lg bg-[#212144]"></div>-->
<!--      <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--      <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--      <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--      <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--      <div class="grow h-1 rounded-lg bg-gray-300"></div>-->
<!--    </div>-->

<!--  </div>-->

</template>

<script>
export default {
  name: "NavBar",
  props:['isHidden'],
  emits: ['nav-back-button'],
  data() {
    return {
      isConnected : true
    }
  },
  methods: {
    connectTesting(){
      this.isConnected = false
    }
  }
}
</script>

<style scoped>
.text-color{
  color: #212144;
}
</style>