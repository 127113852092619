<template>
  <div class="h-screen w-screen bg-black bg-opacity-20 flex items-center justify-center">
    <p class="text-lg text-center text-gray-800 shadow-2xl p-4 rounded-lg bg-white w-72">
      {{textInfo}}
    </p>
  </div>
</template>

<script>
export default {
  name: "InfoCard",
  props:['textInfo']
}
</script>

<style scoped>

</style>