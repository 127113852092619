
const SUPPLY_MAX_DIGIT = 14

function isEmpty(str) {
    return !str || str.length === 0 ;
}
function hasSpecialChar(textValue) {
const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
return specialChars.test(textValue);
}

function hasSpace(textValue) {
    return /\s/g.test(textValue);
}

export function isNameValid(value){
    return !isEmpty(value) && !hasSpecialChar(value)
}

export function isSupplyValid(value){
    return !isEmpty(value) && !hasSpecialChar(value) && !hasSpace(value) && value.length <= SUPPLY_MAX_DIGIT
}


export function isTickerValid(value){
    return !isEmpty(value) && !hasSpecialChar(value) && !hasSpace(value) && value.length <= SUPPLY_MAX_DIGIT
}