<template>

    <div class="h-20 w-full bg-white flex justify-between items-center">
      <div class="flex ml-8 items-center  w-52">
        <img class="h-12 w-12 p-1 border-blue-300 border-4 rounded-full" src="../assets/logo_footer.svg" alt="">
        <h1 class="text-blue-300 text-lg ml-2 font-bold">OSIS Launchpad</h1>
      </div>


      <div  v-if="isInCreateTokenPage">
      <div class="lg:hidden block text-xl font-bold text-[#4FA9DB]">
        {{progressBarPage}}/8
      </div>
      <div class="lg:flex  lg:grow lg:justify-center items-center lg:block hidden">
       <NavProgressBar :is-active="progressBarPage > 0" :is-final="false"/>
        <NavProgressBar :is-active="progressBarPage > 1" :is-final="false"/>
        <NavProgressBar :is-active="progressBarPage > 2" :is-final="false"/>
        <NavProgressBar :is-active="progressBarPage > 3" :is-final="false"/>
        <NavProgressBar :is-active="progressBarPage > 4" :is-final="false"/>
        <NavProgressBar :is-active="progressBarPage > 5" :is-final="false"/>
        <NavProgressBar :is-active="progressBarPage > 6" :is-final="false"/>
        <NavProgressBar :is-active="progressBarPage > 7" :is-final="true"/>
      </div>

    </div>


      <div class="mr-8 h-10 flex flex-row-reverse  w-10 lg:w-52 ">
        <img  class="h-10 w-10" src="../assets/help-circle.svg" alt="">
      </div>
    </div>
</template>

<script>


import NavProgressBar from "@/components/NavProgressBar";
export default {
  name: "FooterInfo",
  props:['isInCreateTokenPage', 'progressBarPage'],
  components: {NavProgressBar},
  data(){
    return {

    }
  },
}
</script>

<style scoped>

</style>