<template>
  <div class="flex flex-col items-center space-y-4 justify-center">
    <button
        @click="this.$emit('onOptionClick',options.type)"
        class="border-2 rounded-3xl w-48  flex flex-col justify-evenly items-center py-6 space-y-4 relative"
        :class="{ 'border-gray-400': options.isDeactivated , 'border-[#4FA9DB]': !options.isDeactivated ,
            'border-4' :   options.isSelected , 'border-2' :   !options.isSelected
    }"
        :disabled="options.isDeactivated"
    >
      <div
          v-if="options.isSelected"
          class="mx-10 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm"
      >
        <div class="w-3 h-3 bg-[#4FA9DB] rounded-full"></div>
      </div>

      <div
          v-else
          class="mx-10 w-5 h-5 border-2  rounded-full flex justify-center items-center text-sm"
          :class="{ 'border-gray-400': options.isDeactivated , 'border-[#4FA9DB]': !options.isDeactivated}"
      ></div>
      <img
          class="h-24 my-4 aspect-square"
          :src="options.image"
      />
      <p
          :class="{ 'text-gray-400': options.isDeactivated , 'text-[#4FA9DB]': !options.isDeactivated ,'font-bold' : options.isSelected ,'font-normal' : !options.isSelected}"
          class="text-lg text-[#4FA9DB]"
      >
        {{options.name}}
      </p>
      <div v-if="options.isDeactivated"
           class="text-sm text-gray-400 absolute bottom-1"
      >
        Coming Soon
      </div>
    </button>
    <div class="w-48 flex flex-col items-center relative h-20">
      <img
          class="h-6 w-6 show-info "
          src="../assets/information-icon.svg"
      />
      <p
          class="bg-white text-center text-gray-800 p-2 m-2 overflow-visible rounded-md hide max-w-6xl text-sm"
      >
        {{infoBox}}
      </p>
    </div>
  </div>
</template>

<script>
import {infoBoxStringObject} from "@/wizards_pages/data_holder/CardOptionsList";

export default {
  name: "CardOption",
  props:{
    options:{
    }
  },
  emits:['onOptionClick'],
  data () {
    return {
      infoBox : infoBoxStringObject[this.options.type]
    }
  }
}
</script>

<style scoped>
.show-info:hover + .hide {
  visibility: visible;
}
.hide {
  visibility: hidden;
}

</style>