<template>

  <div
      class="w-full flex flex-col justify-center items-center relative"
  >
    <img
        @click="this.$emit('showInfoBox', options.type)"
        src="../assets/information-icon.svg"
        class="absolute right-6"
        alt=""
    />
    <button
        @click="this.$emit('onOptionClick',options.type)"
        class="border-2  rounded-3xl flex justify-start items-center py-6 px-2 w-64 relative"
        :class="{ 'border-gray-400': options.isDeactivated , 'border-[#4FA9DB]': !options.isDeactivated}"
        :disabled="options.isDeactivated"
    >
      <div
          v-if="options.isSelected"
          class="mx-6 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm"
      >
        <div class="w-3 h-3 bg-[#4FA9DB] rounded-full"></div>
      </div>
      <div
          v-else
          class="mx-6 w-5 h-5 border-2  rounded-full flex justify-center items-center text-sm"
          :class="{ 'border-gray-400': options.isDeactivated , 'border-[#4FA9DB]': !options.isDeactivated}"
      ></div>
      <img
          class="w-1/6 mr-6 aspect-square"
          :src="options.image"
      />
      <p
          class="text-lg text-center absolute w-1/2 right-0"
          :class="{ 'text-gray-400': options.isDeactivated , 'text-[#4FA9DB]': !options.isDeactivated ,'font-bold' : options.isSelected ,'font-light' : !options.isSelected}"
      >
        {{options.name}}
      </p>
      <div v-if="options.isDeactivated"
           class="text-sm text-gray-400 absolute bottom-1 text-center w-full"
      >
        {{}}
      </div>
    </button>
  </div>


</template>

<script>
export default {
  name: "CardOptionMobile",
  props:{
    options:{
    }
  },
  emits:['showInfoBox', 'onOptionClick']

}
</script>

<style scoped>

</style>