<template>
  <div class="h-screen w-screen relative">
    <div class="absolute flex flex-col w-full h-full z-10 ">
      <div class="w-full grow  flex flex-col justify-center items-center space-y-6 text-center max-w-5xl mx-auto">
        <img src="./assets/osis-logo-ic.svg" alt="">
        <h1 class="text-color text-3xl font-bold px-4">
          Welcome to the OSIS Launchpad
        </h1>
        <p class="text-color text-md font-medium w-4/5 ">
          Create your own blockchain assets, tokens, and NFTs in minutes - at a fraction of the cost.
          OSIS Launchpad (Patent Pending) is brought to you by OSIS.
        </p>
        <div class="h-6"/>
        <outline-button button-text="Start" @click="login()"/>
      </div>

    </div>
    <BackGround class="absolute z-0"/>
  </div>
</template>

<script>
import Lottie from './lottie.vue';
import Background from "@/components/Background";
import Footer from "@/components/Footer";
import CardButton from "@/components/CardButton";
import NavBar from "@/components/NavBar";
import OutlineButton from "@/components/OutlineButton";


export default {
  name: 'HomeComponent',
  components: {
    OutlineButton,
      'lottie': Lottie,
      'BackGround' : Background,
      'Footer' : Footer,
      'CardButton' : CardButton,
      'navBar' : NavBar
    },
  data() {
    return {
      
      hasEthProvider: true,
      walletAddress: "",
      nonce: "",
      hasError: false,
      loading: false,
      completed: false,
      success: false,
      errorMessage: ""
    }
  },
  methods: {
    async login() {
       await this.$router.push({name: 'token_create', params: {}});
      // await this.$router.push({name: 'testing'});
    },
    printmesage(){
      console.log('nav back clicked !!')
    }
  }
}
</script>

<style scoped>

.text-color{
  color: #212144;
}

</style>
