import Currency from "@/assets/crypto_icon.svg";
import UniqueNFT from "@/assets/unique_nft.svg";
import NFTCollection from "@/assets/nft_collection.svg";
import FixedSupply from  "@/assets/fixed-supply-ic.svg";
import CanIncrease from  "@/assets/can_mint_icon.svg";
import CanDecrease from  "@/assets/can_burn_icon.svg";
import CanVote from  "@/assets/can_vote.svg";
import Polygon from "@/assets/poly-ic.svg";
import Ethereum from "@/assets/eth-ic.svg";
import Goerli from "@/assets/goerli-ic.svg";
import PublicNFT from "@/assets/public-nft-ic.png";
import PrivateNFT from "@/assets/private-nft-ic.png";

import LaunchingReal from "@/assets/launching-ic.svg";

import LaunchingTestnet from "@/assets/testing-ic.svg";


export const infoBoxStringObject = {
    "Ethereum" :"Pros: Most widely used + highest compatibility. Cons: Highest transaction fees.",
    "Polygon" : "Pros: Most popular for NFTs + compatible with ETH wallets + Lowest transaction fees.",
    "Mumbai": "HIGHLY RECOMMENDED. Choose this if you ultimately plan to launch your project on the Polygon blockchain. Mumbai network allows you to create & test all the features of your token for free before you launch on the main Polygon network.",
    "Goerli" : "HIGHLY RECOMMENDED. Choose this if you ultimately plan to launch your project on the Ethereum blockchain. Goerli network allows you to create & test all the features of your token for free before you launch on the main Ethereum network.",
    "ERC20" : "A token or currency, similar to Bitcoin, that can represent shares of your business. Currencies are frequently used to sell a part of your business to investors & raise funding.",
    "ERC721" : "A single Non-Fungible Token - an item on the blockchain which can represent any physical or digital asset.",
    "ERC1155": "More than 1 NFT collection - up to tens of thousands of units. Big projects like Bored Ape Yacht Club create NFT Collections in order to tokenize a special membership pass. Your imagination is your only limit - NFTs can represent any physical or digital asset.",
    "Name": "Choose something memorable! This is what the world will see in exchanges & all over the internet. *This cannot be changed after it\’s launched*",
    "Ticker": "Do a little research on CoinMarketCap.com to make sure your name isn’t already taken. This cannot be changed after it is launched.",
    "Supply" : "If you choose Mintable & Burnable in the next step, you can always adjust your total token supply in the future.",
    "Mint": "This feature allows you to add more tokens to the total supply after launching. This is good for projects that want to be flexible with their tokenomics.",
    "Burn" : "This feature allows you to remove tokens from the total supply after launching. This is good for projects that want to be flexible with their tokenomics.",
    "Fixed":"Like Bitcoin, the total number of tokens you have can never change.",
    "Vote" : "This feature allows holders to vote on things you permit them to. This is a useful feature for Decentralized Autonomous Organizations or projects that want to have a democratic system.",
    "TraditionalNFT" : "Traditional NFTs usually include related metadata information. There can be data related to the rights associated with each token. Anyone who has access to the blockchain can also view the public metadata.",
    "PrivateNFT" : "Private NFTs work just like traditional NFTs except for some programmable privacy features. One of the biggest highlights of private NFTs is their ability to enable private ownership for holders. Private NFT owners can choose who can access their NFT's content. Assets and transactions get complete protection and privacy as they do not get exposed to unauthorized people."
}
export const deploymentWaitingScreenMessagesList = [
    'Leaving could result in a failing transaction.',
    'Can take some time depending on the network traffic.',
    'Patience is a virtue',
]

export let tokenTypeList = [
    {
        id:1,
        type:"ERC20",
        isSelected: false,
        image: Currency ,
        name: 'Currency',
        isDeactivated: false,

    },
    {
        id:2,
        type:"ERC721",
        isSelected: false,
        image: UniqueNFT ,
        name: 'A Unique NFT',
        isDeactivated: false,

    },
    {
        id:3,
        type:"ERC1155",
        isSelected: false,
        image: NFTCollection ,
        name: 'NFT Collection',
        isDeactivated: true,
    },
]


export let tokenFeaturesList = [
    {
        id:1,
        type:"Fixed",
        isSelected: true,
        image: FixedSupply ,
        name: 'Fixed Supply',
        isDeactivated: false,

    },
    {
        id:2,
        type:"Mint",
        isSelected: false,
        image: CanIncrease ,
        name: 'Can Increase Supply',
        isDeactivated: false,

    },
    {
        id:3,
        type:"Burn",
        isSelected: false,
        image: CanDecrease ,
        name: 'Can Decrease Supply',
        isDeactivated: false,
    },
    {
        id:4,
        type:"Vote",
        isSelected: false,
        image: CanVote ,
        name: 'Can Vote',
        isDeactivated: true,

    }
]

export let realNetworkList = [
    {
        id:1,
        type:"Ethereum",
        isSelected: false,
        image: Ethereum ,
        name: 'Ethereum',
        isDeactivated: false,

    },
    {
        id:2,
        type:"Polygon",
        isSelected: false,
        image: Polygon ,
        name: 'Polygon',
        isDeactivated: false,

    },
]
export let testnetNetworkList = [

    {
        id:1,
        type:"Goerli",
        isSelected: false,
        image: Ethereum ,
        name: 'Goerli ETH Testnet',
        isDeactivated: false,
    },
    {
        id:2,
        type:"Mumbai",
        isSelected: false,
        image: Polygon ,
        name: 'Mumbai Testnet',
        isDeactivated: false,

    }
]

export let NFTPrivacyList = [
    {
        id:1,
        type:"TraditionalNFT",
        isSelected: false,
        image: PublicNFT ,
        name: 'Traditional NFT',
        isDeactivated: false,

    },
    {
        id:3,
        type:"CustomIdNFT",
        isSelected: false,
        image: PublicNFT ,
        name: 'Custom ID NFT',
        isDeactivated: false,
    },
    {
        id:2,
        type:"PrivateNFT",
        isSelected: false,
        image: PrivateNFT ,
        name: 'Private NFT',
        isDeactivated: true,

    }
]

export let TypeOfLaunchingList = [
    {
        id:1,
        type:"RealDeal",
        isSelected: false,
        image: LaunchingReal ,
        name: 'Real Deal',
        isDeactivated: false,

    },
    {
        id:2,
        type:"Testnet",
        isSelected: false,
        image: LaunchingTestnet ,
        name: 'Testnet',
        isDeactivated: false,

    }
]

