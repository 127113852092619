<template>

  <div  class="w-full">
      <div v-if="tokenType!=='ERC721'"
          style="border-width: 2px"
          class="h-16 m-2  rounded-full border-gray-200 flex  items-center justify-between px-6 inputBorderColor"
      >
        <h3 class="text-md text-left  font-normal text-gray-600">
          Features:
        </h3>

        <select
            class="text-md text-right font-normal text-blue-400 bg-transparent  outline-none inputBorderColor"
            :value="tokenFeatureString"
            @input="$emit('onFeatureChange',$event.target.value)"
        >
          <option selected >Fixed</option>
          <option >Can Increase</option>
          <option >Can Decrease</option>
          <option >Can Increase and Decrease</option>
        </select>
      </div>
    </div>
   <div class="w-full">
      <div
          style="border-width: 2px"
          class="h-16 m-2 rounded-full border-gray-200 flex  items-center justify-between px-6"
      >
        <h3 class="text-md text-left font-normal text-gray-600">
          Token type:
        </h3>
        <h3 class="text-md text-right font-normal  text-blue-400">
        {{tokenType}}
        </h3>
      </div>

      <div class="h-16 m-2 flex items-center justify-between px-6">
        <h3 class="text-md text-left font-medium text-gray-700">Fee</h3>
        <h3 class="text-md text-right font-normal text-gray-600">
          {{tokenFee}}
        </h3>
      </div>
</div>
</template>

<script>


export default {
  name: "SummaryPage",
  props:['tokenFee' , 'tokenType', 'tokenFeatureString'],
  data(){
    return {

    }
  },
  emits: ['onFeatureChange']


}
</script>



<style scoped>
.inputBorderColor:focus-within {
  border-color: #4fa9db;
}
</style>