<template>

    <div class="h-full w-full flex flex-col justify-center items-center">
      <h1 class="text-3xl px-10 text-center font-bold text-gray-800 mb-8 ">
        Please Confirm Transaction
      </h1>
      <lottie  :options="defaultDeploymentOptionsAnimation" :height="300" :width="300" v-on:animCreated="handleAnimation"/>
      <div class="text-xl p-6 text-center font-light text-gray-800">
        Check wallet to confirm transaction!
      </div>
    </div>

</template>

<script>
import Lottie from "@/lottie";
import * as deploymentAnimationData from "@/assets/animations_json/waiting-for-confirmation-lottie.json";

export default {
  name: "ConfirmationScreen",
  components: {
    'lottie': Lottie
  },
  data() {
    return {
      defaultDeploymentOptionsAnimation: {animationData: deploymentAnimationData},
    }
  }
}
</script>

<style scoped>

</style>