import {BigNumber} from "ethers";
const { ethers } = require("ethers");
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3Modal from "web3modal";
import {
  ERC20,
  ERC721,
  ERC1155,
  ERC20GoerliAddress,
  ERC20MumbaiAddress,
  ERC20EthMainnetAddress,
  ERC20PolygonAddress,
  ERC721EthereumAddress,
  ERC721GoerliAddress,
  ERC721PolygonAddress,
  ERC721MumbaiAddress,
  ERC1155Address,
  RPC_URLS,
} from "./utils/constants";
import { erc20FactoryABI } from "./abis/erc20factory";
import { erc20PaidFactoryABI } from "./abis/erc20factorypaid";
import { erc721FactoryABI } from "./abis/erc721factory";
import { erc1155FactoryABI } from "./abis/erc1155factory";
import axios from "axios";
import connect from './composables/connect/index';
import { utils } from 'ethers';
import * as deploymentAnimationData from './assets/animations_json/deploying-lottie.json';
import * as errorAnimationData from './assets/animations_json/error-lottie.json';
import Lottie from './lottie.vue';
import * as walletAnimationData from './assets/animations_json/wallet-lottie.json';
import * as osisLogoAnimationData from './assets/animations_json/osis-logo-lottie.json'
import * as importAnimationData from './assets/animations_json/coin-into-wallet-mobile-lottie.json'
import * as congratulationAnimationData from './assets/animations_json/congratulation-lottie.json'
import ConnectWallet from './wizards_pages/ConnectWallet.vue'
import Background from './components/Background.vue'
import Footer from './components/Footer.vue'
import NavBar from './components/NavBar.vue'
import OutlineButton from './components/OutlineButton.vue'
import CardOption from "@/components/CardOption";
import CardOptionMobile from "@/components/CardOptionMobile";

import InputFieldOutline from "@/components/InputFieldOutline";
import TokenName from "@/wizards_pages/TokenName";
import InfoCard from "@/components/InfoCard";
import TokenTicker from "@/wizards_pages/TokenTicker";
import TokenSupply from "@/wizards_pages/TokenSupply";
import {
  infoBoxStringObject,
  networkList, NFTPrivacyList, realNetworkList, testnetNetworkList,
  tokenFeaturesList,
  tokenTypeList, TypeOfLaunchingList
} from "@/wizards_pages/data_holder/CardOptionsList";
import Summary from "@/wizards_pages/Summary";
import ErrorDialog from "@/wizards_pages/ErrorDialog";
import ConfirmationScreen from "@/wizards_pages/ConfirmationScreen";
import DeploymentScreen from "@/wizards_pages/DeploymentScreen";
import {isNameValid, isSupplyValid, isTickerValid} from "@/wizards_pages/utils/ImputValidation";
import pdf from "@jbtje/vue3pdf";
import EncryptingFileDialog from "@/wizards_pages/EncryptingFileDialog";
import UploadFileScreen from "@/wizards_pages/UploadFileScreen";
import TokenNFTDesc from "@/wizards_pages/TokenNFTDesc.vue";
import {erc721ABI} from "@/abis/erc721Child";
const { disconnectWallet } = connect();

export default {
  
  components: {
    'lottie': Lottie,
    'connect-page' : ConnectWallet,
    'BackGround' : Background,
    'Footer' : Footer,
    'navBar' : NavBar,
    'outline-button': OutlineButton,
    'CardOptionMobile': CardOptionMobile,
    'CardOption': CardOption,
    'InputField': InputFieldOutline,
    'TokenName': TokenName,
    'InfoCard': InfoCard,
    'TokenTicker' : TokenTicker,
    'TokenSupply': TokenSupply,
    'Summary': Summary,
    'ErrorDialog': ErrorDialog,
    'TokenNFTDesc': TokenNFTDesc,
    "ConfirmationScreen" : ConfirmationScreen,
    'DeploymentScreen': DeploymentScreen,
    'pdf' : pdf,
    EncryptingFileDialog,
    'UploadFileScreen': UploadFileScreen

  },
  name: "CreateToken",
  props: {},
  data() {
    return {
      // Data for loading pdf and images into the browser memory
      isFileSelected: false,
      // To preview the loaded file
      filePreview : null,
      // the object that hold the file
      selectedFile: null,
      // for ui preview
      isImage: false,
      // rest of the data
      isTokenInTestNet: null,
      typeOfLaunchingOptions: TypeOfLaunchingList,
      nftPrivacyOption: NFTPrivacyList,
      networkOptions: null,
      tokenTypeOptions: tokenTypeList ,
      tokenFeaturesOption: tokenFeaturesList,
      isErrorDialogVisible: false,
      tokenData:{
        name: "",
        ticker: "",
        supply: null,
        isMintable: false,
        isBurnable: false,
        fee: '',
        type: null,
        isNFTPrivate : null,
        description : ""
      },
      defaultCongartOptionsAnimation: {animationData: congratulationAnimationData, loop: false},
      defaultImportOptionsAnimation: {animationData: importAnimationData},
      defaultDeploymentOptionsAnimation: {animationData: deploymentAnimationData},
      defaultLogoOptionsAnimation: {animationData: osisLogoAnimationData},
      defaultErrorOptionsAnimation: {animationData: errorAnimationData},
      defaultWalletOptionsAnimation: {animationData: walletAnimationData},
      feeString : "",
      useTokenFeatureString: true,
      tokenFeatureString: "Fixed",
      tokenAddedSuccefully: false,
      isInputValid: true,
      congratulationIcon: false,
      accountBalance: "",
      isLoading: false,
      hasError: false,
      hasSuccess: false,
      errorMessage: "",
      successMessage: "",
      name: null,
      uri: "",
      symbol: null,
      tokenSupply: null,
      isBurnable: false,
      isMintable: false,
      isvoteable: false,
      isMintWithUniqueId: false,
      tokenType: "ERC20",
      nameList: "",
      idList: "",
      erc1155NameList: [],
      erc1155IdList: [],
      erc20: ERC20,
      erc721: ERC721,
      erc1155: ERC1155,
      openTab: 0,
      networkType: null,
      provider: {},
      isConnected: false,
      isTokenReady: false,
      tokenAddress: "",
      tokenSymbol: "",
      circleTabs: [false, false, false, false, false, false],
      lineTabs: [false, false, false, false, false, false],
      connectionResponse: null,
      symbolTrim: null,
      infoStringObject: infoBoxStringObject,
      isInfoBoxVisible: false,
      infoStringValue:"",
      navProgressPage : 1,
      isNavBarVisible : true,
      isEncryptionDialogVisible : false,

    };
  },
  async mounted() {

  },
  methods: {
    // methods for loading file
    loadFile(){
      this.selectedFile = this.$refs.file.files[0];
      if (this.selectedFile.name.includes(".png") || this.selectedFile.name.includes(".jpg") || this.selectedFile.name.includes(".jpeg")  ) {
        this.isFileSelected = true
        this.isImage = true
        this.filePreview = URL.createObjectURL(this.selectedFile);
      }
      else if(this.selectedFile.name.includes(".pdf")){
        this.isFileSelected = true
        this.isImage = false
        this.filePreview = URL.createObjectURL(this.selectedFile);
      } else {
        this.selectedFile = null
        this.isFileSelected = false
        console.log("file type not supported!")
      }
    },
    closeFile(){
      this.isFileSelected = null
      this.isFileSelected = false
      this.isImage = false
      this.selectedFile = null
    },
    async validateFile(){
      if(this.selectedFile == null){
        return
      }
      console.log(this.selectedFile)
      // for checking the size
      console.log("The file Size is =",this.selectedFile.size)

      // const pdfBlob = new Blob([this.selectedFile], { type: 'application/pdf' });
      // const pdfUrl = URL.createObjectURL(pdfBlob);
      // window.open(pdfUrl);

      // //string Data for encrypting
      // const stringData = await this.selectedFile.text();
      // console.log("file Buffer Data = ", stringData)
      //
      // const testRes = await this.uploadTest()
      // console.log('testRes:', testRes)
    },
    async uploadTest(){

      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      formData.append('key', '0xf986AB80D7bC2EF37bb8A6D536b7718218705e7a');
      console.log('formData = ', formData);
      const uploadImageResponse = await axios.post('https://osisplatform.com/api/storeImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          secret: 'c6fvb3fyqKvUcpvujxFwoMoN',
        },
      })
      console.log('response.data = ', uploadImageResponse.data);
      return uploadImageResponse;
    },
    async upload() {
      // FormData is another way of sending data to the api.
      // We will be using FormData for uploading multiple files.
      // For now, we will only be uploading one file.
      // const formData = new FormData();
      // formData.append('file', this.selectedFile, this.selectedFile.name);
      const uploadImageResponse = await axios.post('https://api.nft.storage/upload', this.selectedFile, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDAzRUJhODM1NDdlYjlkN2M5RDNEMmUzQUFjMTVmNzc1NzMwN2NDMDQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3NzI4MDc3NjAxMywibmFtZSI6IkxhdW5jaHBhZFN0b3JlS2V5In0.VTxwONCLcFGBcO9IV64URAkSDoNHTKGDi2XwxFwmnqc',
        }, //TODO: at some point we will want to hide
      })
      console.log('response.data = ', uploadImageResponse.data);

      if(this.tokenData.description === ""){
        this.tokenData.description = `Contract for Minting ${this.tokenData.name} Tokens`
      }

      const metadata = {
        name: this.tokenData.name,
        description: this.tokenData.description,
        image: `ipfs://${uploadImageResponse.data.value.cid}`,
        // attributes: [],
        properties:{
          name:{
            type:"string",
            description: this.tokenData.name
          },
          description:{
            type:"string",
            description:`Contract for Minting ${this.tokenData.name} Tokens`
          },
          image:{
            type:"string",
            description:`ipfs://${uploadImageResponse.data.value.cid}`
          }
        }
      };
      const metadataJSON = JSON.stringify(metadata);

      const uploadMetadataResponse = await axios.post('https://api.nft.storage/upload', metadataJSON, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDAzRUJhODM1NDdlYjlkN2M5RDNEMmUzQUFjMTVmNzc1NzMwN2NDMDQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3NzI4MDc3NjAxMywibmFtZSI6IkxhdW5jaHBhZFN0b3JlS2V5In0.VTxwONCLcFGBcO9IV64URAkSDoNHTKGDi2XwxFwmnqc',
        }, //TODO: at some point we will want to hide
      })
      console.log('uploadMetadataResponse.data = ', uploadMetadataResponse.data);
      return uploadMetadataResponse.data.value.cid;
    },
    // rest of methods
    isTokenDataValid(){
      return isNameValid(this.tokenData.name) && isSupplyValid(this.tokenData.supply) && isTickerValid(this.tokenData.ticker)
    }
    ,async onSummary(){
      const web3Provider = new ethers.providers.Web3Provider(this.provider);
      const network = await web3Provider.getNetwork();
      switch (network.name) {
        case "goerli":
          this.tokenData.fee = "0.0061 ETH";
          break;
        case "homestead":
          this.tokenData.fee = "0.0061 ETH";
          break;
        case "matic":
          this.tokenData.fee = "8.00 MATIC";
          break;
        case "maticmum":
          this.tokenData.fee = "0.1 MATIC";
          break;
        default:
          this.tokenData.fee = ""
      }
      this.toggleTabs(6)

    },
    showInfoBox(type){
        if(type === null ){
          this.isInfoBoxVisible = false
          this.infoStringValue =""
          return
        }
        this.isInfoBoxVisible = true
        this.infoStringValue = this.infoStringObject[type]
    }
    ,async disconnectUser(){
     // await disconnectWallet()
      this.isConnected = false
    },

    async connectWallet() {
      console.log("click-connect")
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: RPC_URLS
          }
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            appName: "Osis Launchpad",
            chainId: 1, // Optional. It defaults to 1 if not provided
            darkMode: false, // Optional. Use dark theme, defaults to false
            rpc: RPC_URLS
          }
        }
      };
      const web3Modal = new Web3Modal({
        providerOptions // required
      });
      this.provider = await web3Modal.connect();
      const web3Provider = new ethers.providers.Web3Provider(this.provider);
      const signer = web3Provider.getSigner();

      const address = await signer.getAddress();
      const network = await web3Provider.getNetwork();
      const balance = await signer.getBalance();

      console.log("address = ", address);
      console.log("network.name = ", network.name);
      console.log(`balance = ${balance}`);
      console.log(`balance[eth] = ${ethers.utils.formatEther(balance)}`);


      this.accountBalance = ethers.utils.formatEther(balance);

      try {
        await this.requestProviderAccounts();
        this.isConnected = true;
      } catch (error) {
        console.log("Can't connect error= ", error);
        this.isConnected = false;
      }
      await this.providerEvents()
    },
    async requestProviderAccounts() {
      this.provider
          .request({ method: 'eth_requestAccounts' })
          .then((accounts) => {
            console.log('Accounts:', accounts);
          })
          .catch((error) => {
            if (error.code === 4001) {
              // EIP-1193 userRejectedRequest error
              console.log('Hey! Why you reject? Please connect a wallet.');
            } else {
              console.log('something aint right');
              console.error(error);
            }
          });
    },
    async providerEvents() {
      // Subscribe to provider connection
      this.provider.on("connect", ( { chainId: number }) => {
        console.log("onConnect - chain:", number);
      });
      // Subscribe to provider disconnection
      this.provider.on("disconnect", ({ message: string }) => {
        console.log("onDisconnect - chain:", string);
      });
      // Subscribe to chainId change
      this.provider.on("chainChanged", (chainId) => {
        this.setFeeStringFromChanId( chainId)
        console.log("onChainChanged: ", chainId );
      });
    },setFeeStringFromChanId(chainId){
      switch (chainId) {
        case "0x5":
          this.tokenData.fee = "0.0061 ETH";
          break;
        case "0x1":
          this.tokenData.fee = "0.0061 ETH";
          break;
        case "0x89":
          this.tokenData.fee = "8.0 MATIC";
          break;
        case "0x13881":
          this.tokenData.fee = "0.1 MATIC";
          break;
      }
    }
    ,sendPostRequest: function () {
      const headers = {};
      const params = {};
      axios.post("", params, headers).then(function (response) {
        console.log(response.data);
      });
    },
    sendGetRequest: function () {
      const headers = {};
      const params = {};
      axios.get("", params, headers).then(function (response) {
        console.log(response.data);
      });
    },navigationFromTokenTicker(){
      let type = this.tokenData.type;
      if(type === ERC20){
        this.toggleTabs(4);
      }else {
        this.tokenData.supply = "1" ;
        this.tokenData.isBurnable = true ;
        this.tokenData.isMintable = true ;
        this.toggleTabs(-7) ;



      }


    },
    setUnloadWindow(isEnable) {
      window.onbeforeunload = function () {
        return isEnable
            ? "Are you sure you want to leave OSIS LaunchPad?"
            : null;
      };
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
      this.changeNavProgressBar(tabNumber);

    },changeNavProgressBar( pageValue){
      switch (pageValue) {
        case 0 : {
          this.navProgressPage = 1
          break;
        }
        case -5 : {
          this.navProgressPage = 7
          break;
        }
        case -4 : {
          this.navProgressPage = 7
          break;
        }
        case 1 : {
          this.navProgressPage = 2
          break;
        }
        case -2 : {
          this.navProgressPage = 2
          break;
        }
        case -3 : {
          this.navProgressPage = 2
          break;
        }
        case 2  : {
          this.navProgressPage = 3
          break;
        }
        case 3  : {
          this.navProgressPage = 4
          break;
        }
        case 4  : {
          this.navProgressPage = 5
          break;
        }
        case 5  : {
          this.navProgressPage = 6
          break;
        }
        case 6  : {
          this.navProgressPage = 8
          this.isNavBarVisible = true
          break;
        }
        default : {
          this.isNavBarVisible = false
          break;
        }

      }
    },
    async createToken() {

      //  Wrap with Web3Provider from ethers.js
      const web3Provider = new ethers.providers.Web3Provider(this.provider);
      const signer = await web3Provider.getSigner();
      const address = await signer.getAddress();
      const network = await web3Provider.getNetwork();

      console.log("network.name = ", network.name)

      if (this.tokenData.type === ERC20) {
        let factoryAddress = "";
        let EIP1559 = false;
        let cost;
        switch (network.name) {
          case "goerli": {
            factoryAddress = ERC20GoerliAddress;
            cost = "0.0061";
            EIP1559 = true;
            break;
          }
          case "homestead": {
            factoryAddress = ERC20EthMainnetAddress;
            cost = "0.0061";
            EIP1559 = true;
            break;
          }
          case "matic": {
            factoryAddress = ERC20PolygonAddress;
            cost = "8";
            EIP1559 = true;
            break;
          }
          case "maticmum": {
            factoryAddress = ERC20MumbaiAddress;
            cost = "0.01"; // faucets only give 0.5 Mumbai
            EIP1559 = true;
            break;
          }
          default: {
            factoryAddress = ERC20GoerliAddress;
            cost = "0.0061";
            EIP1559 = true;
            break;
          }
        }
        console.log("factoryAddress = ", factoryAddress)
        console.log("this.name = ", this.tokenData.name)
        console.log("this.symbol = ", this.tokenData.ticker)
        console.log("this.supply = ", this.tokenData.supply)
        console.log("this.isBurnable = ", this.tokenData.isBurnable)
        console.log("this.isMintable = ", this.tokenData.isMintable)

        this.setUnloadWindow(false);
        let abiFree = erc20FactoryABI
        let abiPaid = erc20PaidFactoryABI
        let progenitorContract;
        try {
          progenitorContract = new ethers.Contract(
              factoryAddress,
              abiPaid,
              signer
          );
        } catch (error) {
          console.log("error", error)
        }
        let estimation;
        try {
          estimation = await progenitorContract.estimateGas.create(
              "Cool","CL", true, true, 10000000000, {
                value: ethers.utils.parseEther(cost)
              });
        } catch (error) {
          this.navBackToSummaryWithErrorMessage("Not enough Ether provided. Please add funds to your wallet and try again!");
          console.log(" estimation error", error) ;
          return;
        }

        console.log("estimation[number] = ", estimation.toNumber())
        const limit = Math.ceil(estimation.toNumber() * 1.5) // increase by 50%
        console.log("estimation[number * 1.5] = ", limit)

        const feeData = await web3Provider.getFeeData()
        let unitPrice = BigNumber.from(2*1000*1000) // default in Wei => 2 Gwei
        let minerTip = BigNumber.from(1.5*1000*1000) // default in Wei => 1.5 Gwei
        console.log("unitPrice 1:", unitPrice.toNumber())
        console.log("EIP1559[bool] = ", EIP1559)
        if(EIP1559){
          unitPrice = Math.ceil(feeData.maxFeePerGas.toNumber() * 1.24) // increase max by 24%
          minerTip = Math.ceil(feeData.maxPriorityFeePerGas.toNumber() * 1.48) // increase max by 48%
        }else {
          unitPrice = Math.ceil(feeData.gasPrice.toNumber() * 2) // increase fixed price by 100%
        }
        console.log("unitPrice 2:", unitPrice)
        if(network.name !== "homestead") unitPrice = Math.ceil(unitPrice * 1.48) // increase max by additional 48%
        if(network.name === "matic") minerTip = Math.ceil(unitPrice * 0.48)
        console.log("unitPrice 3:", unitPrice)
        console.log("minerTip = ", ethers.utils.formatUnits(BigNumber.from(minerTip), "gwei"))
        console.log("price[gwei] = ", ethers.utils.formatUnits(BigNumber.from(unitPrice), "gwei"))
        console.log("feeData[maxPriorityFeePerGas] = ", ethers.utils.formatUnits(feeData.maxPriorityFeePerGas,"gwei"))
        console.log("feeData[maxFeePerGas] = ", ethers.utils.formatUnits(feeData.maxFeePerGas, "gwei"))
        console.log("feeData[gasPrice] = ", ethers.utils.formatUnits(feeData.gasPrice, "gwei"))

        try {
          await progenitorContract.create(
              this.tokenData.name,
              this.tokenData.ticker,
              this.tokenData.isBurnable,
              this.tokenData.isMintable,
              this.tokenData.supply,
              {
                maxPriorityFeePerGas: BigNumber.from(minerTip),
                maxFeePerGas:BigNumber.from(unitPrice),
                gasLimit: BigNumber.from(limit),
                value: ethers.utils.parseEther(cost),
              }
              );

          this.toggleTabs(8);

        } catch (error) {
          console.log("error", error);
          let errorMessage = ""
          if( error.code === 4001){
            errorMessage = "Please confirm the transaction in order to deploy your token!";
          }else{
            errorMessage = "We couldn't create your token please try again!";
          }
          this.navBackToSummaryWithErrorMessage(errorMessage);
          return;
        }

        this.setUnloadWindow(true);

        try{
          await progenitorContract.on(
              "Created",
              (_token) => {
                console.log(' tokene created is _token =', _token);
                this.tokenAddress = _token.tokenAddress;
                this.tokenSymbol = _token.symbol;
                this.isTokenReady = true;
              }
          );
        } catch (e) {
          let errorMessage = "An error has agreed when receiving the transaction detailed back for your wallet provider. " +
              "Please check if the transaction was successful before trying again!";
          this.navBackToSummaryWithErrorMessage(errorMessage);
          console.log("error =", e)
        }
      }

      if(this.tokenData.type === ERC721){

        let factoryAddress = "";
        switch (network.name) {
          case "goerli": {
            factoryAddress = ERC721GoerliAddress;
            break;
          }
          case "homestead": {
            factoryAddress = ERC721EthereumAddress;
            break;
          }
          case "matic": {
            factoryAddress = ERC721PolygonAddress;
            break;
          }
          case "maticmum": {
            factoryAddress = ERC721MumbaiAddress;
            break;
          }
          default: {
            factoryAddress = ERC721MumbaiAddress;
            break;
          }
        }
        let EIP1559 = true;

        console.log("this.name = ", this.tokenData.name)
        console.log("this.symbol = ", this.tokenData.ticker)
        console.log("this.isBurnable = ", this.tokenData.isBurnable)
        console.log("this.isMintable = ", this.tokenData.isMintable)
        console.log("this.isMintWithUniqueId = ", this.isMintWithUniqueId)
        console.log("this.uri = ", this.uri)

        this.setUnloadWindow(false);
        let progenitorContract;
        try {
          progenitorContract = new ethers.Contract(
              factoryAddress,
              erc721FactoryABI,
              signer
          );
        } catch (error) {
          console.log("error", error)
        }
        let estimation;
        try {
          estimation = await progenitorContract.estimateGas.create("Cool","CL", true, true, false, '0xFeHash55');
        } catch (error) {
          this.navBackToSummaryWithErrorMessage("Not enough Ether provided. Please add funds to your wallet and try again!");
          console.log(" estimation error", error) ;
          return;
        }

        console.log("estimation[number] = ", estimation.toNumber())
        const limit = Math.ceil(estimation.toNumber() * 1.5) // increase by 50%
        console.log("estimation[number * 1.5] = ", limit)

        const feeData = await web3Provider.getFeeData()
        let unitPrice = BigNumber.from(2*1000*1000) // default in Wei => 2 Gwei
        let minerTip = BigNumber.from(1.5*1000*1000) // default in Wei => 1.5 Gwei
        console.log("unitPrice 1:", unitPrice.toNumber())
        console.log("EIP1559[bool] = ", EIP1559)
        if(EIP1559){
          unitPrice = Math.ceil(feeData.maxFeePerGas.toNumber() * 1.24) // increase max by 24%
          minerTip = Math.ceil(feeData.maxPriorityFeePerGas.toNumber() * 1.48) // increase max by 48%
        }else {
          unitPrice = Math.ceil(feeData.gasPrice.toNumber() * 1.8) // increase fixed price by 80%
        }
        console.log("unitPrice 2:", unitPrice)
        if(network.name !== "homestead") unitPrice = Math.ceil(unitPrice * 1.50) // increase max by additional 50%
        if(network.name === "matic") minerTip = Math.ceil(unitPrice * 0.50)
        console.log("unitPrice 3:", unitPrice)
        console.log("minerTip = ", ethers.utils.formatUnits(BigNumber.from(minerTip), "gwei"))
        console.log("price[gwei] = ", ethers.utils.formatUnits(BigNumber.from(unitPrice), "gwei"))
        console.log("feeData[maxPriorityFeePerGas] = ", ethers.utils.formatUnits(feeData.maxPriorityFeePerGas,"gwei"))
        console.log("feeData[maxFeePerGas] = ", ethers.utils.formatUnits(feeData.maxFeePerGas, "gwei"))
        console.log("feeData[gasPrice] = ", ethers.utils.formatUnits(feeData.gasPrice, "gwei"))

        try {
          const metaCID = await this.upload()
          this.uri = metaCID
          await progenitorContract.create(
              this.tokenData.name,
              this.tokenData.ticker,
              true, // forcing isBurnable to be true for now
              true, // forcing isMintable to be true for now
              this.isMintWithUniqueId,
              `ipfs://${metaCID}`,
              {
                maxPriorityFeePerGas: BigNumber.from(minerTip),
                maxFeePerGas:BigNumber.from(unitPrice),
                gasLimit: BigNumber.from(limit),
              }
          );

          this.toggleTabs(8);

        } catch (error) {
          console.log("error", error);
          let errorMessage = ""
          if( error.code === 4001){
            errorMessage = "Please confirm the transaction in order to deploy your token!";
          }else{
            errorMessage = "We couldn't create your token please try again!";
          }
          this.navBackToSummaryWithErrorMessage(errorMessage);
          return;
        }

        this.setUnloadWindow(true);

        try{
          await progenitorContract.on(
              "Created",
              async (_token) => {
                console.log('_token =', _token);
                this.tokenSymbol = _token.symbol;

                // let functionAbi = ["function safeMint(address to, string memory uri) public onlyOwner"];
                // const contract = new ethers.Contract(
                //     _token.tokenAddress,
                //     functionAbi,
                //     signer
                // );
                // try{
                //   if(!this.isMintWithUniqueId){
                //     const result = await contract.safeMint(address, `ipfs://${this.uri}`);
                //     console.log('NFT mint result:', result);
                //   }
                // }catch (e) {
                //   let errorMessage = "An error has agreed when receiving the transaction detailed back for your wallet provider. " +
                //       "Please check your wallet to see if transaction was successful before trying again!";
                //   this.navBackToSummaryWithErrorMessage(errorMessage);
                //   console.log("error =", e)
                // }
                this.tokenAddress = _token.tokenAddress;
                this.isTokenReady = true;
              }
          );
        } catch (e) {
          let errorMessage = "An error has occurred when receiving the transaction detailed back for your wallet provider. " +
              "Please check if the transaction was successful before trying again!";
          this.navBackToSummaryWithErrorMessage(errorMessage);
          console.log("error =", e)
        }

      }

    }
    ,async submit(){
      if(!this.isTokenDataValid()){
        return
      }
      try {
        // adding the upload screen if nft
        // todo use function some clean up when upload is ready
        console.log("token type set ", this.tokenData.type)
        if(this.tokenData.type === ERC721){
          console.log("running nft upload")
          this.toggleTabs(10);
          console.log("switching is ", this.openTab)
          setTimeout(async () => {
            console.log('call back after timer !!!')
            this.toggleTabs(7);
            await this.createToken();
          },5000);
        }else {
          this.toggleTabs(7);
          await this.createToken();

        }

      } catch (error) {
        this.setUnloadWindow(true);
        this.toggleTabs(6);
        this.errorMessage = error.message;
      }

    },
    navBackToSummaryWithErrorMessage(message){
      this.isErrorDialogVisible = true;
      this.errorMessage = message;
      this.toggleTabs(6)
    }, discardErrorDialog(){
      this.isErrorDialogVisible = false;
      this.errorMessage = "";
    }
    ,
    async addTokenToWallet() {
      console.log(
          "address form data = ",
          this.tokenAddress,
          "symbole is = ",
          this.tokenSymbol
      );
      const tokenDecimals = 18;
      //const tokenImage = 'http://cdn-icons-png.flaticon.com/512/2395/2395608.png';

      try {
        this.setUnloadWindow(false);
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await this.provider.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: {
              address: this.tokenAddress, // The address that the token is at.
              symbol: this.tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              //image: tokenImage, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          this.congratulationIcon = true;
          console.log("Thanks for your interest!");
          this.tokenAddedSuccefully = true;
        } else {
          console.log("Your loss!");
          this.setUnloadWindow(true);
        }
      } catch (error) {
        this.setUnloadWindow(true);
        console.log(error);
      }
    },

    changeTokenFeature(type){
      switch (type){
        case "Fixed" : {
          this.tokenData.isMintable = false;
          this.tokenData.isBurnable = false;
          break;
        }
        case "Mint":{
          this.tokenData.isMintable = !this.tokenData.isMintable;
          break;
        }
        case "Can Increase":{
          this.tokenData.isBurnable = false;
          this.tokenData.isMintable = true;
          break;
        }
        case "Burn":
          this.tokenData.isBurnable = !this.tokenData.isBurnable;
          break;
        case  "Can Decrease":
          this.tokenData.isMintable = false;
          this.tokenData.isBurnable = true;
          break;
        case "Can Increase and Decrease" : {
          this.tokenData.isMintable = true;
          this.tokenData.isBurnable = true;
          break;
        }
      }

      let isFixed = !this.tokenData.isMintable && !this.tokenData.isBurnable;
      this.updateTokenFeatureOption("Fixed", isFixed)
      this.updateTokenFeatureOption("Mint", this.tokenData.isMintable)
      this.updateTokenFeatureOption("Burn", this.tokenData.isBurnable)
      this.updateTokenString()
    },updateTokenString(){
      if(this.tokenData.isMintable){
        if(this.tokenData.isBurnable){
          this.tokenFeatureString= "Can Increase and Decrease"
          return;
        }
        this.tokenFeatureString= "Can Increase"
      }else if (this.tokenData.isBurnable){
        this.tokenFeatureString= "Can Decrease"
      }else {
        this.tokenFeatureString= "Fixed"
      }

    },
    updateTokenFeatureOption(type, value){
      let obj = this.tokenFeaturesOption.find(item => item.type === type);
      obj.isSelected = value;
    },
    changeLaunchingOption(type){
      this.typeOfLaunchingOptions.forEach(item =>
          item.isSelected = false
      )
      let obj = this.typeOfLaunchingOptions.find( item => item.type === type )
      obj.isSelected = true
      if(type === "RealDeal"){
        this.isTokenInTestNet = false
        this.networkOptions = realNetworkList
      }else{
        this.isTokenInTestNet = true
        this.networkOptions = testnetNetworkList
      }
    },
    validateNetwork(){
      if(this.networkType === null){
        return;
      }
      this.onSummary()
    }
    ,async changeNetworkType(type) {
      const web3Provider = new ethers.providers.Web3Provider(this.provider);
      const network = await web3Provider.getNetwork();

      this.networkOptions.forEach(item =>
          item.isSelected = false
      )
      let obj = this.networkOptions.find( item => item.type === type )
      obj.isSelected = true

      this.networkType = type;
      let chainId = "";
      let rpcUrl = "";
      let chainName = "";
      let symbol = "";
      let scanUrl = "";
      if (type === "Polygon") {
        if (network.name == "matic") {
          return;
        }
        chainId = "0x89";
        rpcUrl = "https://polygon-rpc.com";
        chainName = "Polygon Mainnet";
        symbol = "MATIC";
        scanUrl = "https://polygonscan.com/";
      }
      if (type === "Ethereum") {
        if (network.name == "homestead") {
          return;
        }
        chainId = "0x1";
        rpcUrl = "https://mainnet.infura.io/v3/";
        chainName = "Ethereum";
        symbol = "ETH";
        scanUrl = "https://etherscan.io";
      }
      if (type === "Goerli") {
        if (network.name == "goerli") {
          return;
        }
        chainId = "0x5";
        rpcUrl = "https://goerli.infura.io/v3/";
        chainName = "Goerli Test Network";
        symbol = "GoerliETH";
        scanUrl = "https://goerli.etherscan.io";
      }
      if (type === "Mumbai") {
        console.log("name of the network is",network.name)
        if (network.name == "maticmum") {
          return;
        }
        chainId = utils.hexValue(80001);
        rpcUrl = "https://rpc-mumbai.maticvigil.com";
        chainName = "Matic(Polygon) Mumbai Testnet";
        symbol = "tMATIC";
        scanUrl = "https://mumbai.polygonscan.com/";
      }

      this.switchNetwork(chainId, chainName, symbol, rpcUrl, scanUrl);
    },
    async switchNetwork(chainId, chainName, symbol, rpcUrl, scanUrl) {
      console.log("rpcUrl = ", rpcUrl);
      if (this.provider) {
        try {
          // check if the chain to connect to is installed
          this.setUnloadWindow(false);
          await this.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainId }], // chainId must be in hexadecimal numbers
          });
        } catch (error) {
          // This error code indicates that the chain has not been added to MetaMask
          // if it is not, then install it into the user MetaMask
          if (error.code === 4902 || chainId === "0x89") {
            try {
              await this.provider.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: chainId,
                    chainName: chainName,
                    nativeCurrency: {
                      name: symbol,
                      symbol: symbol, // 2-6 characters long
                      decimals: 18,
                    },
                    rpcUrls: [rpcUrl],
                    blockExplorerUrls: [scanUrl],
                  },
                ],
              });
              this.setUnloadWindow(true);
            } catch (addError) {
              console.error(addError);
              console.log("I cant add this");
            }
          }
          console.error(error);
        }
      } else {
        alert(
            "MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"
        );
      }
    },
    validateTLaunchingType() {
      if(this.isTokenInTestNet === null){
        return;
      }
      this.toggleTabs(-4)

    },
    validateNumber(value) {
      console.log("input is = ", value);
      this.tokenSupply = value.replace(/\D/g, "");
      console.log("text value  is = ", this.tokenSupply);
      document.getElementById("tokenSupplyId").value = this.tokenSupply;
    },
    nextPage() {
      const currentPage = this.openTab;
      this.toggleTabs(currentPage + 1);
    },
    backNavigation() {
      console.log("current page status:",this.openTab)
      const currentPage = this.openTab;
      if (currentPage === 6){
        if(this.tokenData.type === ERC721){
          this.toggleTabs(3) ;
          return ;
        }
            }
      if (currentPage > -1) {
        this.toggleTabs(currentPage - 1);
      }
      if(currentPage === -4){
        this.toggleTabs(-5)
      }
      if(currentPage === 0){
        this.$router.push({name: 'home'});
      }
      if(currentPage === -5){
        this.toggleTabs(5)
      }
      if(currentPage === -2){
        this.toggleTabs(-3)
      }
      if(currentPage === -3){
        this.toggleTabs(1)
      }

    }
  ,
    generateRandomToken() {
      const first = [
        "Weege",
        "Bob",
        "Fred",
        "Sky",
        "Vanilla",
        "Chocolate",
        "Diamond",
        "Neptune",
        "Mars",
        "Purple",
        "Glass",
        "Stable",
        "Horse",
        "Dogo",
        "Cat",
      ];
      const last = [
        "Project",
        "Token",
        "Coin",
        "Rocket",
        "Moon",
        "Blast",
        "Centauri",
        "System",
        "Light",
        "Galaxy",
        "Currency",
        "Token",
        "Coin",
        "Project",
        "Rocket",
      ];
      // returns random first and last
      const rf = first[Math.floor(Math.random() * first.length)];
      const rl = last[Math.floor(Math.random() * last.length)];
      console.log("Name->", rf + rl);
      this.tokenData.name = rf + rl;
      // returns only consonants - capitalized
      const sf = rf
          .toUpperCase()
          .match(/[^aeiou]/gi)
          .join();
      const sl = rl
          .toUpperCase()
          .match(/[^aeiou]/gi)
          .join();
      // returns only first and last letter
      const symF = sf.charAt(0) + sf.charAt(sf.length - 1);
      const symL = sl.charAt(0) + sl.charAt(sl.length - 1);
      console.log("symbol->", symF + symL);
      this.tokenData.ticker = symF + symL;
      this.tokenData.supply = "1000";
    },
    changeTokenType(type){
      this.tokenTypeOptions.forEach(item =>
          item.isSelected = false
      );
      let obj = this.tokenTypeOptions.find( item => item.type === type );
      this.tokenData.type = type;
      obj.isSelected = true;
    },
    changePrivacyType(type){
      this.nftPrivacyOption.forEach( item =>
          item.isSelected = false
      );
      let obj = this.nftPrivacyOption.find(item => item.type === type);
      obj.isSelected = true;
      console.log(obj)

      if(type === "TraditionalNFT"){
        this.tokenData.isNFTPrivate = false
        this.isMintWithUniqueId = false;
        console.log('Public NFT is selected')
      }
      if(type === "CustomIdNFT"){
        this.isMintWithUniqueId = true;
        this.tokenData.isNFTPrivate = false;
        console.log('CustomId NFT is selected');
      }
      if(type === "PrivateNFT"){
        this.tokenData.isNFTPrivate = true
        this.isMintWithUniqueId = false;
        console.log('Private NFT is selected')
      }
    },
    validateNFTPrivacy(){
      let privacy = this.tokenData.isNFTPrivate
      if(privacy === null){
        return;
      }
      this.toggleTabs(-2)
    },
    validateTokenType(){
      let type = this.tokenData.type
      if(type === null){
        return;
      }
      if(type === ERC20){
        this.nextPage()
      }else {
        this.toggleTabs(-3)
      }
    },
    onLaunchRealDeal(){
      this.changeLaunchingOption("RealDeal")
      this.toggleTabs(-4)
      this.tokenAddedSuccefully = false;
      this.isTokenReady = false;
      this.tokenAddress = "";
    },
    // uploading document checking if the file need to be encrypted show encryption dialog
    async prepareFile() {
      if (this.tokenData.isNFTPrivate) {
        this.isEncryptionDialogVisible = true
        this.$refs.encryptionDialog.statProgress()
      } else {
        await this.validateFile()
        if(this.selectedFile !== null){
          this.toggleTabs(2)
        }
      }
    },
    onEncryptionProgressDone(){
      this.isEncryptionDialogVisible = false
      this.toggleTabs(2)
    }
  },
};