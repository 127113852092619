<template>
  <div
      v-if="isActive"
      class="w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center "
  >
    <div class="w-3 h-3 bg-[#4FA9DB] rounded-full"></div>
  </div>
  <div v-else
       class="w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center ">
  </div>

  <div v-if="!isFinal" class="w-16 h-1 bg-[#4FA9DB]"
  ></div>
</template>

<script>
export default {
  name: "NavProgressBar",
  props:['isActive', 'isFinal']
}
</script>

<style scoped>

</style>