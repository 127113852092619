<template>
  <div v-if="isInSummary" class="w-full">
    <div
        style="border-width: 2px"
        :class="{ 'border-red-800': isTokenNotValid  }"
        class="h-16 m-2 rounded-full border-gray-200 flex items-center justify-between px-6 inputBorderColor"
    >
      <h3 class="text-md text-center font-normal text-gray-600 whitespace-nowrap">
        Project Name:
      </h3>
      <input
          type="text"
          :value="modelValue"
          @input="onInput($event.target.value)"
          onkeydown="return /[a-z0-9 ]/i.test(event.key)"
          class="text-md text-right font-normal bg-transparent grow h-full text-blue-400 outline-none"
      />
    </div>
  </div>
  <div v-else  >
  <h1 class="text-color text-3xl font-bold px-6 mb-10">
    What is the name of your project?
  </h1>
  <div class="w-full flex flex-col items-center space-y-4" >
    <input
        placeholder="Type the name here..."
        type="text"
        :value="modelValue"
        @input="onInput($event.target.value)"
        onkeydown="return /[a-z0-9 ]/i.test(event.key)"
        class="text-gray-700 font-normal centered w-4/5 text-lg rounded-full border-2  p-4 px-8 outline-0"
        :class="{ 'border-[#4FA9DB]': !isTokenNotValid ,'border-red-800': isTokenNotValid  }"
    />
      <img
          @click="this.$emit('showInfoBox')"
          src="../assets/information-icon.svg"
          class="sm:h-6"
          alt=""
      />
  </div>
  <div v-if="isTokenNotValid" class="text-sm text-gray-800 px-8">
    Project name cannot contain special characters (ie $# !@).
  </div>
  <outline-button  button-text="Next" @click="validateName"/>
  </div>

</template>

<script>
import OutlineButton from "@/components/OutlineButton";
import {isNameValid} from "@/wizards_pages/utils/ImputValidation";


export default {
  name: "TokenName",
  components: {OutlineButton},
  props: ['modelValue' , 'isInSummary'],
  data(){
    return {
      isTokenNotValid : false
    }
  },
  methods:{
    onInput(value){
      this.$emit('update:modelValue',value)
      if(!isNameValid(value)){
        this.isTokenNotValid = true
      }else {
        this.isTokenNotValid = false
      }
    }
    ,
    validateName(){
      if(this.isTokenNotValid || this.modelValue.trim() === ""){
        return
      }
      this.$emit('onNameValidated')
    }
  },
  emits: ['onNameValidated', 'showInfoBox', 'update:modelValue']
}
</script>

<style scoped>

.inputBorderColor:focus-within {
  border-color: #4fa9db;
}
</style>