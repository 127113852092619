<template>
  <input
      :placeholder='placeholder'
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :onkeydown="onkeydown"
      :maxlength="mymax"
      class="text-gray-700 font-normal centered w-3/4 text-lg rounded-full border-2 border-[#4FA9DB] p-4 px-8"
  />
</template>

<script>
export default {
  props: ['modelValue',
      'placeholder','type','onkeydown', 'mymax'
  ],
  emits: ['update:modelValue'],
  name: "InputFieldOutline"
}
</script>

<style scoped>

</style>