<template>
  <div v-if="isInSummary" class="w-full">
    <div
        style="border-width: 2px"
        :class="{ 'border-red-800': isTickerNotValid  }"
        class="h-16 m-2 rounded-full border-gray-200 flex items-center justify-between px-6 inputBorderColor"
    >
      <h3 class="text-md text-center font-normal text-gray-600 whitespace-nowrap">
        Token Ticker:
      </h3>
      <input
          type="text"
          :value="modelValue"
          @input="onInput($event.target.value)"
          onkeydown="return /[a-z0-9 ]/i.test(event.key)"
          maxlength="7"
          class="text-md text-right font-normal bg-transparent grow h-full text-blue-400 outline-none"
      />
    </div>
  </div>
  <div v-else>
  <h1 class="text-color text-3xl font-bold px-6 mb-10">
    What is your token ticker?
  </h1>
  <h2 class="text-color text-xl font-normal px-6 mb-10">
    Example: Bitcoin is BTC, Ethereum is ETH. A short form ticker that will be displayed on exchanges when your token is traded.
  </h2>
  <div class="w-full flex flex-col items-center space-y-4" >
    <input
        placeholder="Type the name here..."
        type="text"
        :value="modelValue"
        @input="onInput($event.target.value)"
        onkeydown="return /[a-z0-9 ]/i.test(event.key)"
        maxlength="7"
        class="text-gray-700 font-normal centered w-4/5 text-lg rounded-full border-2  p-4 px-8 outline-0"
        :class="{ 'border-[#4FA9DB]': !isTickerNotValid ,'border-red-800': isTickerNotValid  }"
    />
    <img
        @click="this.$emit('showInfoBox')"
        src="../assets/information-icon.svg"
        class="sm:h-6"
        alt=""
    />
  </div>
  <div v-if="isTickerNotValid" class="text-sm text-gray-800 mt-4">
    Ticker cannot contain spaces or special characters!
    Ticker cannot contain more than 7 characters.
  </div>
  <outline-button  button-text="Next" @click="validateInput"/>
  </div>
</template>

<script>
import OutlineButton from "@/components/OutlineButton";
import { isTickerValid} from "@/wizards_pages/utils/ImputValidation";


export default {
  name: "TokenTicker",
  props: ['modelValue' , 'isInSummary'],
  components: {OutlineButton},
  data(){
    return {
      isTickerNotValid : false
    }
  },
  methods:{
    onInput(value){
      this.$emit('update:modelValue',value)
      if(!isTickerValid(value)){
        this.isTickerNotValid = true
      }else {
        this.isTickerNotValid = false
      }
    },
    validateInput(){
      if( this.isTickerNotValid || this.modelValue.trim() === ""){
        return
      }
      this.$emit('onInputValidated', this.modelValue)
    }
  },
  emits: ['onInputValidated','showInfoBox' , 'update:modelValue']
}
</script>

<style scoped>
.inputBorderColor:focus-within {
  border-color: #4fa9db;
}
</style>