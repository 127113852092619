<template>
  <button @click="" class="flex items-center justify-between space-x-3 text-center py-3 px-8 relative text-lg font-medium text-[#4FA9DB]">
    <p class="grow">{{buttonText}}</p>
    <img v-if="isWithArrow" class="" src="../assets/left-arrow.svg" alt="">

  </button>

</template>

<script>
export default {

  name: "CardButton",
  props: [
      'buttonText',
      'isWithArrow'
  ],
  data() {
    return{
      isArrow: this.isWithArrow ? true : false,

    }
  }


}
</script>

<style scoped>
button{
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 20px -1px rgba(36, 86, 145, 0.16);
  backdrop-filter: blur(25px);
  border-radius: 50px;
}
button:hover{
  background-color: rgba(79, 169, 219, 0.02);
}
</style>