<template>
  <div class="h-full w-full flex flex-col justify-center items-center">
    <h1 class="text-3xl px-10 text-center font-bold text-gray-800 mb-8 ">
      Please wait while we add your {{isEncrypted ? 'encrypted' : ''}} file to the blockchain.
    </h1>
    <lottie  :options="defaultAnimation" :height="300" :width="300" v-on:animCreated="handleAnimation"/>
    <div class="text-xl p-6 text-center font-light text-gray-800">
      This process can take time depending on your upload speed.
    </div>
  </div>
</template>

<script>
import * as UploadAnimation from "@/assets/animations_json/upload-anim.json";
import Lottie from "@/lottie";
export default {
  name: "UploadFileScreen",
  props:['isEncrypted'],
  components : {
    'lottie': Lottie
  },
  data () {
    return {
      defaultAnimation : {
        animationData:  UploadAnimation
      }
    }
  }
}
</script>

<style scoped>

</style>