<template>
 
<div class="h-screen w-screen relative min-screen-height">
    <div class=" absolute flex flex-col w-full h-full ">
      <nav-bar @nav-back-button="backNavigation" :is-hidden="openTab > 6"/>

      <!-- Page -1 Connection-->
        <main v-bind:class="{ hidden: openTab !== 0, block: openTab === 0 }"
         class="h-full w-full flex flex-col grow justify-center items-center space-y-6 text-center max-w-5xl mx-auto relative" >
          <div v-if="isConnected" class="rounded-full  mb-6 h-14 w-44  flex justify-end items-center bg-gray-300   relative transition-opacity  ">
            <h1 class="text-xl px-2 font-medium text-gray-700">3ad...2971</h1>
            <img class="h-12 w-12 p-2 mr-1 rounded-full bg-[#212144]" src="./assets/osis-logo-ic-white.svg" alt="">
          </div>
         <connect-page :is-connected="isConnected" />
         <div v-if="!isConnected">
          <outline-button @click="connectWallet" button-text='Connect'/>
         </div>
         <div v-else class="flex flex-col space-y-6 justify-center items-center">
<!--           <outline-button @click="disconnectUser" button-text='Disconnect'/>-->

           <outline-button class="animate-bounce" @click="toggleTabs(1)" button-text='Next'/>

<!--         <outline-button @click="onSummary()" class="sm:hidden block" button-text="Skip Wizard"/>-->
         </div>
          <div v-if="isConnected" class=" h-20 w-44  ">

          </div>
        </main>

      <!-- Page 0 NetworkSwitch ignored for Testing-->
      <main v-bind:class="{ hidden: openTab !== -4, block: openTab === -4}"
            class="h-full w-full flex flex-col grow justify-start sm:justify-center items-center space-y-6 text-center max-w-5xl mx-auto" >
        <h1 class="text-color text-3xl font-bold px-6 sm:mt-0 mt-4 mb-8 ">
          Which network do you want to create your token on?
        </h1>
        <CardOptionMobile class="sm:hidden block" v-for="item in networkOptions" :key="item.id" :options="item" @show-info-box="(type) => showInfoBox(type)" @on-option-click="(type) => changeNetworkType(type)"/>

        <div class="flex justify-evenly w-full space-x-3">
          <CardOption class="sm:block hidden" v-for="item in networkOptions" :key="item.id" :options="item" @on-option-click="(type) => changeNetworkType(type)"/>
        </div>
        <outline-button button-text="Next" @click="validateNetwork"/>

      </main>

      <!-- Page  -->
      <main v-bind:class="{ hidden: openTab !== -5, block: openTab === -5}"
            class="h-full w-full flex flex-col grow justify-start sm:justify-center items-center space-y-6 text-center max-w-5xl mx-auto" >
        <h1 class="text-color text-3xl font-bold px-6 sm:mt-0 mt-4 mb-8 ">
          Would you like to deploy to test-net first? (Recommended)
        </h1>
        <CardOptionMobile class="sm:hidden block" v-for="item in typeOfLaunchingOptions" :key="item.id" :options="item" @show-info-box="(type) => showInfoBox(type)" @on-option-click="(type) => changeLaunchingOption(type)"/>

        <div class="flex justify-evenly w-full space-x-3">
          <CardOption class="sm:block hidden" v-for="item in typeOfLaunchingOptions" :key="item.id" :options="item" @on-option-click="(type) => changeLaunchingOption(type)"/>
        </div>
        <outline-button button-text="Next" @click="validateTLaunchingType"/>

      </main>


      <main v-bind:class="{ hidden: openTab !== -2, block: openTab === -2 }"
            class="h-full w-full flex flex-col grow justify-start sm:justify-center items-center space-y-6 text-center max-w-5xl mx-auto" >

        <h1 class="text-color text-3xl font-bold px-10">
          Please upload the image for your NFT.
        </h1>

        <div v-if="!isFileSelected" class="w-[80%] h-1/3 max-w-md rounded-3xl border-2 border-[#4FA9DB] border-dashed flex flex-col justify-center px-4 space-y-5 items-center relative">
          <input type="file" ref="file" class="opacity-0 h-full w-full absolute top-0" accept="image/png, image/jpeg, application/pdf"  @change="loadFile">
          <img src="./assets/cloud-fill-ic.svg">
          <div>
            <div class="text-[#4FA9DB] flex ">Choose file<p class="text-color px-1">or</p>Drag here</div>
            <p class="text-color" >Size limit: 5MB</p>
          </div>
        </div>
        <div v-else-if="isImage" class="w-[80%] max-w-md rounded-3xl  h-1/3  border-2 border-[#4FA9DB]   flex flex-col justify-center items-center  p-4" >
          <div class="relative w-48 h-48 flex justify-center items-center">
            <img class="w-44 h-44 p-1 object-cover  rounded-2xl border-2 border-[#4FA9DB]  border-dashed  drop-shadow-xl " :src="filePreview">
            <img class="bg-white rounded-full h-8 absolute bottom-0 right-0 drop-shadow-md " src="./assets/close-ic.svg" @click="closeFile" >
          </div>
        </div>
        <div v-else class="w-[80%] max-w-md rounded-3xl  h-1/3  border-2 border-[#4FA9DB]   flex flex-col justify-center items-center  p-4" >
          <div class="relative w-48 h-48 flex justify-center items-center object-cover ">
            <pdf class="w-44 h-44 p-1 object-cover overflow-clip  rounded-2xl border-2 border-[#4FA9DB]  border-dashed  drop-shadow-xl " :src="filePreview"/>
            <img class="bg-white rounded-full h-8 absolute bottom-0 right-0 drop-shadow-md " src="./assets/close-ic.svg" @click="closeFile" >
          </div>
        </div>
        <p class="text-color text-sm font-normal  w-4/5 ">
          Please upload the image you want to represent your NFT.
        </p>
        <div class="h-6"/>
        <outline-button v-if="false" button-text="LogFile Info" @click="validateFile"/>
        <outline-button button-text="Next" @click="prepareFile"/>


      </main>



      <!-- Page 1 Connection-->
      <main v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }"
            class="h-full w-full flex flex-col grow justify-start sm:justify-center items-center space-y-6 text-center max-w-5xl mx-auto" >
        <h1 class="text-color text-3xl font-bold px-6 sm:mt-0 mt-8 ">
          What type of token do you want to launch?
        </h1>
        <CardOptionMobile class="sm:hidden block" v-for="item in tokenTypeOptions" :key="item.id" :options="item" @show-info-box="(type) => showInfoBox(type)" @on-option-click="(type) => changeTokenType(type)"/>
        <div class="h-4"/>
        <div class="flex justify-evenly w-full space-x-3">
          <CardOption class="sm:block hidden" v-for="item in tokenTypeOptions" :key="item.id" :options="item" @on-option-click="(type) => changeTokenType(type)"/>
        </div>
        <outline-button button-text="Next" @click="validateTokenType"/>

      </main>

      <main v-bind:class="{ hidden: openTab !== -3, block: openTab === -3}"
            class="h-full w-full flex flex-col grow justify-start sm:justify-center items-center space-y-6 text-center max-w-5xl mx-auto" >
        <h1 class="text-color text-3xl font-bold px-6 sm:mt-0 mt-8 ">
          What type of privacy features do you want to apply to your NFT?
        </h1>
        <CardOptionMobile class="sm:hidden block" v-for="item in nftPrivacyOption" :key="item.id" :options="item" @show-info-box="(type) => showInfoBox(type)" @on-option-click="(type) => changePrivacyType(type)"/>
        <div class="h-4"/>
        <div class="flex justify-evenly w-full space-x-3">
          <CardOption class="sm:block hidden" v-for="item in nftPrivacyOption" :key="item.id" :options="item" @on-option-click="(type) => changePrivacyType(type)"/>
        </div>
        <outline-button button-text="Next" @click="validateNFTPrivacy"/>

      </main>



      <!-- Page 1 Connection-->
      <main v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }"
            class="h-full w-full flex flex-col grow justify-start mt-8 sm:mt-0  sm:justify-center items-center text-center max-w-5xl mx-auto" >
       <TokenName @onNameValidated="nextPage" v-model="tokenData.name" @showInfoBox="showInfoBox('Name')" :is-in-summary="false"/>
      </main>

      <!-- Page 1 Connection-->
      <main v-bind:class="{ hidden: openTab !== 3, block: openTab === 3 }"
            class="h-full w-full flex flex-col grow justify-start mt-8 sm:mt-0  sm:justify-center items-center space-y-10 text-center max-w-5xl mx-auto" >
        <TokenTicker @onInputValidated="navigationFromTokenTicker"  v-model="tokenData.ticker" @showInfoBox="showInfoBox('Ticker')" :is-in-summary="false"/>
      </main>

      <!-- Page 1 Connection-->
      <main v-bind:class="{ hidden: openTab !== 4, block: openTab === 4 }"
            class="h-full w-full flex flex-col grow justify-start mt-8 sm:mt-0  sm:justify-center items-center space-y-10 text-center max-w-5xl mx-auto" >
        <TokenSupply @onInputValidated="toggleTabs(5)" @showInfoBox="showInfoBox('Supply')" :is-in-summary="false" v-model="tokenData.supply"/>
      </main>

      <!-- Page 1 Connection-->
      <main v-bind:class="{ hidden: openTab !== -7, block: openTab === -7 }"
            class="h-full w-full flex flex-col grow justify-start mt-8 sm:mt-0  sm:justify-center items-center space-y-10 text-center max-w-5xl mx-auto" >
        <TokenNFTDesc @onDescValidated="toggleTabs(-5)" @showInfoBox="showInfoBox('Name')" :is-in-summary="false" v-model="tokenData.description"/>
      </main>

      <!-- Page 1 Connection-->
      <main v-bind:class="{ hidden: openTab !== 5, block: openTab === 5 }"
            class="h-full w-full flex flex-col grow justify-start sm:justify-center items-center space-y-6 text-center max-w-5xl mx-auto" >
        <h1 class="text-color sm:text-3xl text-2xl font-bold px-6 sm:mt-0 mt-4 ">
          What extra features do you want in your token?
        </h1>
        <h3 class="text-color text-md font-normal px-6 ">
          What extra features do you want in your token?
        </h3>
        <CardOptionMobile class="sm:hidden block" v-for="item in tokenFeaturesOption" :key="item.id" :options="item" @show-info-box="(type) => showInfoBox(type)" @on-option-click="(type) => changeTokenFeature(type)"/>
        <div class="flex justify-evenly w-full space-x-3">
          <CardOption  class="sm:block hidden" v-for="item in tokenFeaturesOption" :key="item.id" :options="item" @on-option-click="(type) => changeTokenFeature(type)"/>
        </div>
        <outline-button button-text="Next" @click="toggleTabs(-5)"/>
      </main>


      <div
          v-bind:class="{ hidden: openTab !== 6, block: openTab === 6 }"
          class="h-full w-full flex flex-col grow  items-center max-w-2xl mx-auto relative px-4">
        <div class="flex  max-w-xl justify-between items-center px-4">
          <h1 class="text-2xl sm:text-3xl my-4 text-center font-bold text-gray-800 relative grow">
            Summary
          </h1>
          <img v-if="tokenData.type!=='ERC721'"
              @click="generateRandomToken()"
              class="h-10 w-10 absolute right-8"
              src="./assets/random-icon.svg"
              alt=""
          />
        </div>
        <div v-if="tokenData.type==='ERC721' && isImage" class="w-[80%] max-w-md rounded-3xl     flex flex-col justify-center items-center p-4" >
          <img class="w-44 h-44 p-1 object-cover  rounded-2xl border-2 border-[#4FA9DB]  border-dashed  drop-shadow-xl " :src="filePreview">
        </div>
        <div v-if="tokenData.type==='ERC721' && !isImage" class="w-[80%] max-w-md rounded-3xl  h-1/3  border-2 border-[#4FA9DB]   flex flex-col justify-center items-center  p-4" >
          <pdf class="w-44 h-44 p-1 object-cover overflow-clip  rounded-2xl border-2 border-[#4FA9DB]  border-dashed  drop-shadow-xl " :src="filePreview"/>
        </div>
        <TokenName @onNameValidated="toggleTabs(3)" v-model="tokenData.name" @showInfoBox="showInfoBox('Name')" :is-in-summary="true"/>
        <TokenTicker @onInputValidated="toggleTabs(4)"  v-model="tokenData.ticker" @showInfoBox="showInfoBox('Ticker')" :is-in-summary="true"/>
        <TokenSupply v-if="tokenData.type!=='ERC721'" @onInputValidated="toggleTabs(5)" @showInfoBox="showInfoBox('Supply')" v-model="tokenData.supply"  :is-in-summary="true"/>
        <Summary  :token-featureString="tokenFeatureString"  :token-type="tokenData.type" :token-fee="tokenData.fee" @onFeatureChange="(value) => changeTokenFeature(value)" />
        <button @click="submit"
            class="px-8 py-4 rounded-full  border-2 mt-12 border-[#4FA9DB] hover:bg-blue-100">
          <h2 class=" text-[#4FA9DB] font-medium whitespace-nowrap">Launch Project</h2>
        </button>
<!--        <outline-button button-text="Launch Project" @click="submit"/>-->
      </div>


      <div
          v-if=" openTab === 10"
          class=" w-full flex flex-col grow  items-center max-w-2xl mx-auto relative px-4">
        <UploadFileScreen :is-encrypted="tokenData.isNFTPrivate"/>
      </div>

      <div
          v-if=" openTab === 7"
          class=" w-full flex flex-col grow  items-center max-w-2xl mx-auto relative px-4">
        <ConfirmationScreen/>
      </div>

      <div
          v-if=" openTab === 8"
          class="w-full flex flex-col grow  items-center max-w-2xl mx-auto relative px-4">
         <DeploymentScreen  :is-in-test-net="isTokenInTestNet" :token-added-succefully="tokenAddedSuccefully" :is-token-ready="isTokenReady" :token-address="tokenAddress" @onAddTokenClicked="addTokenToWallet" @onLaunchRealDeal="onLaunchRealDeal"/>
      </div>


      <Footer :is-in-create-token-page="isNavBarVisible" :progress-bar-page="navProgressPage" class="hidden lg:flex"/>
    </div>
    <EncryptingFileDialog class="absolute z-30 w-screen h-screen"  ref="encryptionDialog" :is-encrypting-file-dialog = "isEncryptionDialogVisible" @progress-done="onEncryptionProgressDone" />
    <ErrorDialog class="absolute z-30 w-screen h-screen" :IsErrorDialog="isErrorDialogVisible" :error-message="errorMessage"  @onDialogClose="discardErrorDialog"/>
    <BackGround class="absolute -z-10"/>
    <InfoCard v-if="isInfoBoxVisible" class="absolute z-10 top-0" @click="showInfoBox(null)" :text-info="infoStringValue"/>
  </div>

  
</template>

<script src="./createTokenScript.js"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.text-color{
  color: #212144;
}

.blur-box {
  background: linear-gradient(
    197.56deg,
    rgba(255, 255, 255, 1) 18.71%,
    rgba(255, 255, 255, 0.5) 98.35%
  );
  box-shadow: 0px 1px 20px -1px rgba(36, 86, 145, 0.16);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 25px;
}

.blur-next {
  border-radius: 25px 0px 0px 25px;
}

.blur-back {
  border-radius: 0px 25px 25px 0px;
}

.hide {
  visibility: hidden;
}

.showinfo:hover + .hide {
  visibility: visible;
}

.min-size {
  min-width: 700px;
  min-height: 400px;
}

.min-win-size {
  min-width: 1200px;
  min-height: 900px;
}

.create-token select {
  height: 40px;
  background: transparent;
  border: 1px #4fa9db solid;
  color: #4fa9db;
}

.box-bg {
  position: relative;
  background: linear-gradient(180deg, #fafafa 0%, #eff6ff 100%), #ffffff;
  /* background-image: url('./assets/mobile-bg.svg'); */
}
.box-bg-image {
  pointer-events: none;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.4;
  background-image: url("./assets/mobile-bg.svg");
  background-repeat: no-repeat;
  background-clip: border-box;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 11;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #fd6c9e;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.screen-mobile-h {
  min-height: 860px;
}

.inputBorderColor:focus-within {
  border-color: #4fa9db;
}
.z-axes{
  z-index: 1;
}
</style>
