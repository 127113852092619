<template>
  <div>
    <!-- <StatusContainer class="hidden" /> -->
    <Navigation class="hidden"/>
    <router-view v-if="hasEthProvider" />
    <h1 v-else class="no-metamask">Please install the MetaMask browser extension and try again</h1>
  </div>
</template>
<script>
import Navigation from './components/Navigation.vue';
import './assets/tailwind.css'
// import StatusContainer from "./components/StatusContainer.vue";

export default {
    name: "App",
    components: {
      // StatusContainer,
      Navigation,
    },
    data() {
      return {
        hasEthProvider: true
      }
    },
    async mounted() {
      // const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      // if (provider != null)
      // {
      //   this.hasEthProvider = true
      // }
    } 
}
</script>

<style>
.min-screen-height{
  min-height: 800px;
}

</style>
