<template>
  <div v-if="IsErrorDialog"
      class=" w-screen h-screen p-8 flex flex-col justify-center items-center  bg-black bg-opacity-40 "
  >
    <div
        class="flex flex-col justify-center items-center blur-box shadow-lg space-y-8 py-4 w-full max-w-md"
    >
      <div class="text-3xl px-10 text-center font-bold text-gray-800 mb-4">
        Error
      </div>

      <lottie class="rounded-full bg-[#212144] p-4" :options="defaultErrorOptionsAnimation" :height="200" :width="200" v-on:animCreated="handleAnimation"/>
      <div class="text-xl px-6 text-center text-gray-800">
        {{ errorMessage }}
      </div>
      <OutlineButton button-text="Close" @click="$emit('onDialogClose')"/>
    </div>

  </div>
</template>

<script>
import * as errorAnimationData from "@/assets/animations_json/error-lottie.json";
import Lottie from "@/lottie";
import OutlineButton from "@/components/OutlineButton";

export default {
  components: {
    'lottie': Lottie,
    'OutlineButton': OutlineButton
  },
  name: "ErrorDialog",
  props:['IsErrorDialog','errorMessage'],
  emits:['onDialogClose'],
  data()  {
    return {
      defaultErrorOptionsAnimation: {animationData: errorAnimationData},
    }
  }
}
</script>

<style scoped>
.blur-box {
  background: linear-gradient(
      197.56deg,
      rgba(255, 255, 255, 1) 18.71%,
      rgba(255, 255, 255, 0.5) 98.35%
  );
  box-shadow: 0px 1px 20px -1px rgba(36, 86, 145, 0.16);
  backdrop-filter: blur(25px);
  border-radius: 25px;
}
</style>