<template>

  <div >
    <h1 class="text-color text-3xl font-bold px-6 mb-10">
      What is the description of your project?
    </h1>
    <h2 class="text-color text-xl font-normal px-6 mb-10">
      Please provide an optional description to your project.
    </h2>
  <div class="w-full flex flex-col items-center space-y-4" >
    <input
        placeholder="Type here..."
        type="text"
        :value="modelValue"
        @input="onInput($event.target.value)"
        onkeydown="return /[a-z0-9 ]/i.test(event.key)"
        class="text-gray-700 font-normal centered w-4/5 text-lg rounded-full border-2  p-4 px-8 outline-0"
        :class="{ 'border-[#4FA9DB]': !isTokenNotValid ,'border-red-800': isTokenNotValid  }"
    />
      <img
          @click="this.$emit('showInfoBox')"
          src="../assets/information-icon.svg"
          class="sm:h-6"
          alt=""
      />
  </div>
  <div v-if="isTokenNotValid" class="text-sm text-gray-800 px-8">
    Project name cannot contain special characters (ie $# !@).
  </div>
  <outline-button  button-text="Next" @click="validateName"/>
  </div>

</template>

<script>
import OutlineButton from "@/components/OutlineButton";
import {isNameValid} from "@/wizards_pages/utils/ImputValidation";


export default {
  name: "TokenNFTDesc",
  components: {OutlineButton},
  props: ['modelValue' , 'isInSummary'],
  data(){
    return {
      isTokenNotValid : false
    }
  },
  methods:{
    onInput(value){
      this.$emit('update:modelValue',value)
    }
    ,
    validateName(){
      this.$emit('onDescValidated')

    }
  },
  emits: ['onDescValidated', 'showInfoBox', 'update:modelValue']
}
</script>

<style scoped>

.inputBorderColor:focus-within {
  border-color: #4fa9db;
}
</style>