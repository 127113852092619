const ERC20 = "ERC20";
const ERC721 = "ERC721";
const ERC1155 = "ERC1155";

const ERC20GoerliAddress = '0xe031289459D003c183483BCe69a586fCff3e78A0'; // prod v-1.0
// const ERC20GoerliAddress = '0x14deE550016F74A7fdda9d6e3976aee616AEA1E6'; legacy paid version
// const ERC20GoerliAddress = '0xf51e586E1B354B0d1Df5444959a74C6CacBd2D4b'; legacy without fees

const ERC20EthMainnetAddress = '0xff8cCD2495840bCD2ca48d23B48F2c9e74c69786'; // prod v-1.0
// const ERC20EthMainnetAddress = '0x23E50E5322ca7c5E6dDd81730d1bb2B1bcD7Ea19'; paid version
// const ERC20EthMainnetAddress = '0xFe02DB956d2a8954d4f29649E3b73165F52E4440'; old one without fees


const ERC20PolygonAddress = '0xe5a55C2059CD7473cadC129EDf75FBD1C2237b45'; // prod v-1.0
// const ERC20PolygonAddress = '0xda196CF836c18962d21588700EE94f077E6032bf'; // paid version
// const ERC20PolygonAddress = '0x3b56492Ef4Da5996Eb5063298Dd8d3BaaCCf0578'; old one without fees

const ERC20MumbaiAddress = '0xe5a55C2059CD7473cadC129EDf75FBD1C2237b45'; // prod v-1.0
// const ERC20MumbaiAddress = '0x0CB12Cd8e8935Be797b1C0B06896b829D85c240F'; // paid version
// const ERC20MumbaiAddress = '0xeb37E2e0214E7832E01cd68548e70CAb01Ce1feb'; old one without fees

const ERC721EthereumAddress = '0xeb37E2e0214E7832E01cd68548e70CAb01Ce1feb'
const ERC721PolygonAddress = '0x3fF4Da0316A227C7dBBd3B604Ad59B3B878a83eb'
const ERC721MumbaiAddress = '0x23F973eC6a52163898F3Ca97DC9Ffe1B8AE59579'
const ERC721GoerliAddress = '0xFdD756309EAe7bEF9393F6B7e660eD0bE0DC9c8E' // beta v-1.1 (goerli)

const ERC1155Address = '0xf9f550e7310cd1E08158aDbdEB7a6a5E4c659eEd';
    
export {
    ERC20,
    ERC721,
    ERC1155,
    ERC20GoerliAddress,
    ERC20EthMainnetAddress,
    ERC20PolygonAddress,
    ERC20MumbaiAddress,

    ERC721EthereumAddress,
    ERC721GoerliAddress,
    ERC721PolygonAddress,
    ERC721MumbaiAddress,

    ERC1155Address
}

export const RPC_URLS = {
    1: "https://eth-mainnet.g.alchemy.com/v2/srsk00fI6RMw5M0mpzdC3ayeFRMcqJGn",
    137: "https://polygon-mainnet.g.alchemy.com/v2/47sKRQ4eWFI0-6zKdakQAjfUY-BnQnVC",
    5: "https://eth-goerli.g.alchemy.com/v2/IujOeHw6FElFb9cvUbH6mEV0pIVeAN-0",
    80001:
        "https://polygon-mumbai.g.alchemy.com/v2/70_xUCn4WWFWyi1OoFnvocolIQqTpjPL",
}