<template>
  <div v-if="isInSummary"  class="w-full">
    <div
        style="border-width: 2px"
        :class="{ 'border-red-800': isSupplyNotValid  }"
        class="h-16 m-2 rounded-full border-gray-200 flex items-center justify-between px-6 inputBorderColor"
    >
      <h3 class="text-md text-center font-normal text-gray-600 whitespace-nowrap">
        Token Supply:
      </h3>
      <input
          type="number"
          id="tokenSupplyId"
          :value="modelValue"
          @input="onInput($event.target.value)"
          maxlength="14"
          class="text-md text-right font-normal bg-transparent grow h-full text-blue-400 outline-none"
      />
    </div>
  </div>
  <div v-else>
  <h1 class="text-color text-3xl font-bold px-6 mb-10">
    How many tokens do you want to mint?
  </h1>
  <h2 class="text-color text-xl font-normal px-6 mb-10">
    It can be anywhere from one to trillions. Consider your tokenomics
    before taking this step.
  </h2>
  <div class="w-full flex flex-col items-center space-y-4 mb-10" >
    <input
        placeholder="Type your answer here..."
        type="number"
        id="tokenSupplyId"
        :value="modelValue"
        @input="onInput($event.target.value)"
        maxlength="14"
        class="text-gray-700 font-normal centered w-4/5 text-lg rounded-full border-2  p-4 px-8 outline-0"
        :class="{ 'border-[#4FA9DB]': !isSupplyNotValid ,'border-red-800': isSupplyNotValid  }"
    />
    <img
        @click="this.$emit('showInfoBox')"
        src="../assets/information-icon.svg"
        class="sm:h-6"
        alt=""
    />
  </div>
  <div v-if="isSupplyNotValid" class="text-sm text-gray-800">
    Supply can only be a number!
  </div>
  <outline-button  button-text="Next" @click="validateInput"/>
  </div>
</template>

<script>
import OutlineButton from "@/components/OutlineButton";
import {isSupplyValid} from "@/wizards_pages/utils/ImputValidation";

export default {
  name: "TokenSupply",
  components: {OutlineButton},
  props: ['modelValue' , 'isInSummary'],
  data(){
    return {
      isSupplyNotValid : false
    }
  },
  methods:{
    onInput(value){
      this.$emit('update:modelValue',value)
      if(!isSupplyValid(value)){
        this.isSupplyNotValid = true
      }else {
        this.isSupplyNotValid = false
      }
    },
    validateInput(){
      if(this.isSupplyNotValid || this.modelValue === null){
        return
      }
      this.$emit('onInputValidated')
    },
    validateNumber(value) {
      this.tokenSupply = value.replace(/\D/g, "");
      document.getElementById("tokenSupplyId").value = this.modelValue;
    },
  },
  emits: ['onInputValidated', 'showInfoBox', 'update:modelValue']
}
</script>

<style scoped>
.inputBorderColor:focus-within {
  border-color: #4fa9db;
}
</style>