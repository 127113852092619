<template>
  <div class="relative h-screen w-screen page-bg pointer-events-none min-screen-height">
    <img class="w-full bottom-0 absolute" src="../assets/wave-bg.svg">
  </div>
</template>
<script>
export default {
  name: "Background"
}
</script>
<style scoped>
.page-bg{
  background: linear-gradient(180deg, #FFFFFF 0%, #F5FAFF 100%);
}
</style>