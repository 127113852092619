<template>
  <div v-if="isEncryptingFileDialog"
       class=" w-screen h-screen p-10 flex flex-col justify-center items-center  bg-black bg-opacity-50 "
  >
    <div
        class="flex flex-col justify-center items-center blur-box shadow-lg  gap-8 py-6 w-full max-w-md"
    >
      <div class="text-2xl px-10 text-center font-bold text-gray-800 ">
        Encrypting your file
      </div>

      <lottie class="rounded-full bg-[#212144] p-4" :options="defaultAnimation" :height="200" :width="200" />
      <div class="h-4 bg-gray-400 w-4/5 rounded-md">
        <div :style="styleProgress" class=" h-4 bg-[#4FA9DB] w-0 rounded-md">
      </div>

      </div>

    </div>

  </div>
</template>

<script>
import * as EncryptingAnimation from "@/assets/animations_json/encrypting-anim-1.json";
import lottie from "@/lottie";

export default {
  name: "EncryptingFileDialog",
  props:['isEncryptingFileDialog'],
  components:{
    'lottie':lottie
  },
  data() {

    return {
      defaultAnimation : { animationData: EncryptingAnimation },
      progressValue : 0,
      duration : 3000,
      styleProgress : {
        width: '0%',
      },
      interval: null,

  }
  },
  emits:['progressDone']
  ,methods: {
    statProgress() {
      this.interval = setInterval(()=>{
        let random = Math.random() * 25
        this.progressValue += random
        if(this.progressValue > 100){
          this.progressValue = 100
          this.styleProgress.width = this.progressValue.toString().concat('%')
          setTimeout(()=>{
            this.$emit('progressDone')
            clearInterval(this.interval)
            this.progressValue = 0
          },500)
        }
        this.styleProgress.width = this.progressValue.toString().concat('%')
      }, this.duration/3)
    },

  }



}
</script>

<style scoped>
.blur-box {
  background: linear-gradient(
      197.56deg,
      rgba(255, 255, 255, 1) 18.71%,
      rgba(255, 255, 255, 0.5) 98.35%
  );
  box-shadow: 0px 1px 20px -1px rgba(36, 86, 145, 0.16);
  backdrop-filter: blur(25px);
  border-radius: 25px;
}
</style>