<template>
  <div
      class="h-full w-full flex flex-col justify-center items-center"
  >
    <div v-if="isTokenReady">
      <div v-if="!tokenAddedSuccefully">
        <lottie :options="defaultImportOptionsAnimation" :height="300" :width="300" v-on:animCreated="handleAnimation"/>
      </div>
      <div v-else>
        <lottie :options="defaultCongartOptionsAnimation" :height="300" :width="300" v-on:animCreated="handleAnimation"/>
      </div>
    </div>
    <div v-else>
      <lottie class="rounded-full" :options="defaultDeploymentOptionsAnimation" :height="300" :width="300" v-on:animCreated="handleAnimation"/>
    </div>
    <div>
      <h1 v-if="isTokenReady" class="text-3xl px-10 text-center font-bold text-gray-800">
        Congratulations!
      </h1>
      <div v-if="!isTokenReady" class="py-8">
        <h1 class="text-2xl px-10 text-center font-bold text-gray-800">
          DO NOT EXIT THIS PAGE
        </h1>
<!--        <h1-->
<!--            class="text-xl px-10 my-2 text-center font-normal text-gray-800"-->
<!--        >-->
<!--          Doing so could result in a failed transaction. This process may take up to 10 minutes depending on the network's traffic.-->
<!--          <br>patience is a virtue &#8987;-->
<!--        </h1>-->
        <FadeInOutText :messages-list="waitingMessagesList"/>
      </div>

<!--      <h1-->
<!--          class="text-xl px-10 my-2 text-center font-normal text-gray-800"-->
<!--      >-->
<!--        {{-->
<!--          isTokenReady-->
<!--              ? "Your token & smart contracts are now live!"-->
<!--              : "Your token is now being built & deployed to the global blockchain network. This process may take up to 10 minutes, depending on how busy the network is… Please wait."-->
<!--        }}-->
<!--      </h1>-->
      <div v-if="isTokenReady" class="flex flex-col items-center">
        <h2
            class="text-md px-10 my-4 text-center font-light text-gray-800"
        >Your token & smart contracts are now live!<br>Smart contract address: {{ tokenAddress }}
        </h2>
        <a href="https://dashboard.osisplatform.com/signin" target="_blank" rel="noopener noreferrer" class="pt-4 text-blue-500 hover:underline">View Contract on token Dashboard</a>
        <h1
            class="text-xl px-10 my-2 text-center font-normal text-gray-800"
        >
          {{
            tokenAddedSuccefully
                ? "Your token was successfully added to your wallet!"
                : ""
          }}
        </h1>
        <div v-if="!tokenAddedSuccefully">
          <button
              @click="$emit('onAddTokenClicked')"
              class="px-8 py-4 animate-bounce rounded-full flex justify-center items-center border-2 mt-12 border-[#4FA9DB] hover:bg-blue-100"
          >
            <h2 class="text-l text-[#4FA9DB] font-medium">
              Import Your Token
            </h2>
          </button>
        </div>
        <div v-else class="flex flex-col  justify-center items-center" >

          <div v-if="isInTestNet" class="text-lg mt-8 font-medium text-gray-800 px-10 text-center">Would you like to deploy your token to the real blockchain now?</div>
          <button
              @click="onNextStepButtonClick()"
              class="px-8 py-4 animate-bounce rounded-full flex justify-center items-center border-2 mt-12 border-[#4FA9DB] hover:bg-blue-100 relative"
          >
            <h2 class="text-l text-[#4FA9DB] font-medium">{{isInTestNet ? "I am ready!" : "Get Osis"}}</h2>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as congratulationAnimationData from "@/assets/animations_json/congratulation-lottie.json";
import * as importAnimationData from "@/assets/animations_json/coin-into-wallet-mobile-lottie.json";
import * as deploymentAnimationData from "@/assets/animations_json/deploying-lottie.json";
import Lottie from "@/lottie";
import FadeInOutText from "@/components/FadeInOutText";
import {deploymentWaitingScreenMessagesList} from "@/wizards_pages/data_holder/CardOptionsList";

export default {
  name: "DeploymentScreen",
  components: {
    'FadeInOutText':FadeInOutText,
    'lottie': Lottie
  },
  props:[
      'tokenAddedSuccefully',
      'tokenAddress',
      'isTokenReady',
      'isInTestNet'
  ],
  data() {
    return {
      defaultCongartOptionsAnimation: {animationData: congratulationAnimationData, loop: false},
      defaultImportOptionsAnimation: {animationData: importAnimationData},
      defaultDeploymentOptionsAnimation: {animationData: deploymentAnimationData},
      waitingMessagesList : deploymentWaitingScreenMessagesList
    }
  },
  methods:{
    onNextStepButtonClick() {
      if(this.isInTestNet){
        this.$emit('onLaunchRealDeal')
      }
      else {
        window.open("https://dashboard.osisplatform.com/signin", "_blank");
      }

    }
  },
  emits:['onAddTokenClicked', 'onLaunchRealDeal']
}
</script>

<style scoped>

</style>