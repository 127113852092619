<template>

  <div class="h-screen w-screen relative">

    <div class="absolute flex flex-col w-full h-full z-10 ">
      <div class="w-full grow  flex flex-col justify-center items-center space-y-6 text-center max-w-5xl mx-auto">
        <h1 class="text-color text-3xl font-bold px-10">
          Please upload the image for your NFT.
        </h1>

        <div v-if="!isFileSelected" class="w-[80%] h-1/3 max-w-md rounded-3xl border-2 border-[#4FA9DB] border-dashed flex flex-col justify-center px-4 space-y-5 items-center relative">
          <input type="file" ref="file" class="opacity-0 h-full w-full absolute top-0" accept="image/*"  @change="redFile">
          <img src="./assets/cloud-fill-ic.svg">
          <div>
            <div class="text-[#4FA9DB] flex ">Choose file<p class="text-color px-1">or</p>Drag here</div>
            <p class="text-color" >Size limit: 5MB</p>
          </div>
        </div>
        <div v-else class="w-[80%] max-w-md rounded-3xl  h-1/3  border-2 border-[#4FA9DB]   flex flex-col justify-center items-center  p-4" >
          <div class="relative w-48 h-48 flex justify-center items-center">
            <img class="w-44 h-44 p-1 object-cover  rounded-2xl border-2 border-[#4FA9DB]  border-dashed  drop-shadow-xl " :src="previewImage">
            <img class="bg-white rounded-full h-8 absolute bottom-0 right-0 drop-shadow-md " src="./assets/close-ic.svg" @click="closeFile" >
            </div>

        </div>
        <p class="text-color text-sm font-normal  w-4/5 ">
          Please upload the image you want to represent your NFT.
        </p>
        <div class="h-6"/>
        <CardButton button-text="Next" @click="login()" is-with-arrow="true"/>

      </div>
      <Footer/>
    </div>
    <BackGround class="absolute z-0"/>
  </div>
</template>

<script>
import Lottie from "@/lottie";
import Background from "@/components/Background";
import Footer from "@/components/Footer";
import CardButton from "@/components/CardButton";
import NavBar from "@/components/NavBar";



export default {
  name: "TestingPage",
  components: {
    'lottie': Lottie,
    'BackGround' : Background,
    'Footer' : Footer,
    'CardButton' : CardButton,
  },data () {
    return {
      isFileSelected: false,
      previewImage : null,
      inputRef: null
    }
  }
,
  methods: {
    redFile() {
      this.inputRef = this.$refs.file.files[0]
      if (this.inputRef.name.includes(".png") || this.inputRef.name.includes(".jpg")) {
        this.isFileSelected = true;
        this.previewImage = URL.createObjectURL(this.inputRef);
      } else {
        this.isFileSelected = false;
      }
    },
    closeFile(){
      this.inputRef = null
      this.isFileSelected = false;
    }

  }
,
  mounted() {



  }
}
</script>

<style >

.text-color{
  color: #212144;
}
.highlight{
  border-width: 4px;
}

</style>